import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './commodity.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CommodityDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();
  const userAuthorities = useAppSelector(state => state.authentication.account.authorities);

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const commodityEntity = useAppSelector(state => state.commodity.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="commodityDetailsHeading">
          <Translate contentKey="comexApp.commodity.detail.title">Commodity</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.id}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="comexApp.commodity.name">Name</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.name}</dd>
          <dt>
            <span id="description">
              <Translate contentKey="comexApp.commodity.description">Description</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.description}</dd>
          <dt>
            <span id="price">
              <Translate contentKey="comexApp.commodity.price">Price</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.price?.toLocaleString()}</dd>
          <dt>
            <span id="stock">
              <Translate contentKey="comexApp.commodity.stock">Stock</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.stock?.toLocaleString()}</dd>
          <dt>
            <span id="currency">
              <Translate contentKey="comexApp.commodity.currency">Currency</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.currency}</dd>
          <dt>
            <span id="currency">
              <Translate contentKey="comexApp.commodity.ownership">Ownership</Translate>
            </span>
          </dt>
          <dd>{commodityEntity.ownership || 'Bursa'}</dd>

        </dl>
        <Button tag={Link} to="/commodity" replace data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        {userAuthorities.includes(AUTHORITIES.ADMIN) && <Button tag={Link} to={`/commodity/${commodityEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>}
      </Col>
    </Row>
  );
};

export default CommodityDetail;
