import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import {IBankApiSetting, defaultValue} from "app/shared/model/bank-api-setting.model";

const initialState: EntityState<IBankApiSetting> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/bankapisettings';

// Actions

export const getEntityByBankId = createAsyncThunk(
  'bankApiSetting/fetch_entity',
  async (bankId: string | number) => {
    const requestUrl = `${apiUrl}/${bankId}`;
    return axios.get<IBankApiSetting>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'bankApiSetting/create_entity',
  async (entity: IBankApiSetting, thunkAPI) => {
    const result = await axios.post<IBankApiSetting>(apiUrl, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'bankApiSetting/update_entity',
  async (entity: IBankApiSetting, thunkAPI) => {
    const result = await axios.put<IBankApiSetting>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const BankApiSettingSlice = createEntitySlice({
  name: 'bankApiSetting',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isPending(getEntityByBankId), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isFulfilled(getEntityByBankId), (state, action) => {
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(createEntity, updateEntity, getEntityByBankId), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });;
  },
});

export const { reset } = BankApiSettingSlice.actions;

// Reducer
export default BankApiSettingSlice.reducer;
