import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Row, Col, FormGroup, Label } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { styled, createTheme, responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Dialog from '@material-ui/core/Dialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {
  getEntityByIcAndBankId,
  updateEntity as updateCustomer,
} from 'app/entities/customer/customer.reducer';
import { getFeeListByBankId } from 'app/entities/comex-bank-fee/comex-bank-fee.reducer';
import { getCommoditiesByBankId } from 'app/entities/commodity/commodity.reducer';
import { getAllEntities as getCurrencies } from 'app/entities/currency-index/currency-index.reducer';
import { getAllEntities as getCurrencyRates } from 'app/entities/currency-rates/currency-rates.reducer';
// Replaced getEntity with getAllEntities because the first queries PAGINATED QUERIES, i.e. 20 BRANCHES
// import { getEntities as getBranches,getEntity as getBranch } from 'app/entities/branch/branch.reducer';
import { getEntity as getBranch } from 'app/entities/branch/branch.reducer';
import { getEntityByBranchId as getBankByBranchId } from 'app/entities/bank/bank.reducer';
import { getEntity, updateEntity, createEntity, reset } from './quotation.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Storage } from 'react-jhipster';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { getAccount } from 'app/shared/reducers/authentication';
import { createEntity as createCustomer, getAllEntities as getCustomers, verifyExistingCustomerPhoneNumber } from '../customer/customer.reducer';
import { ICustomer, ICustomerQueryParam } from 'app/shared/model/customer.model';
import { IComexBankFee } from 'app/shared/model/comex-bank-fee.model';
import { ICommodity } from 'app/shared/model/commodity.model';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { countryList } from 'app/entities/bank/country';
import './quotation-update.css';
import { getEntity as getComexSetting } from 'app/entities/comex-settings/comex-settings.reducer';

import moment from 'moment/moment';
import { getVerifyExistingEmailUser } from 'app/modules/administration/user-management/user-management.reducer';
export const QuotationUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const historyBack = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(state => state.authentication.account);
  const [isNew] = useState(!props.match.params || !props.match.params.id);
  const commodities = useAppSelector(state => state.commodity.entities);
  const quotationEntity = useAppSelector(state => state.quotation.entity);
  const quotationNo = useAppSelector(state => state.quotation.entity);
  const branchEntity = useAppSelector(state => state.branch.entity);

  const verifyEmailUser = useAppSelector(state => state.userManagement.verifyExistingEmailUser);
  const bankEntity = useAppSelector(state => state.bank.entity);
  const loading = useAppSelector(state => state.quotation.loading);
  const updating = useAppSelector(state => state.quotation.updating);
  const updateSuccess = useAppSelector(state => state.quotation.updateSuccess);
  const currencyRateList = useAppSelector(state => state.currencyRates.entities);
  const currencyIndexList = useAppSelector(state => state.currencyIndex.entities);
  const bankFeeList = useAppSelector(state => state.comexBankFee.entities);
  const account = useAppSelector(state => state.authentication.account);
  const customers = useAppSelector(state => state.customer.entities);
  const [itemDescription, setItemDescription] = useState('');
  const [itemDescriptionInArabic, setItemDescriptionInArabic] = useState('');
  const [itemPrice, setItemPrice] = useState(0);
  const [itemName, setItemName] = useState('');
  const [itemQuantity, setItemQuantity] = useState(0);
  const [amountV, setAmount] = useState(1);

  const [amountDisplayed, setAmountDisplayed] =  useState('');
  const [currencyName, setCurrencyName] = useState('');
  const [displayCurrencyName, setDisplayCurrencyName] = useState('');
  const [isDefaultCurrency, setIsDefaultCurrency] = useState(false);
  const [cusIc, setCusIc] = useState('');
  const [cusIdSearch, setCusIdSearch] = useState('');
  const [cusName, setCusName] = useState('');
  const [cusNationality, setNationality] = useState('');
  const [cusNationalityArabic, setNationalityArabic] = useState('');
  const [phone, setPhone] = useState('');
  const [cusEmail, setCusEmail] = useState('');
  const [newEditCus, setNewEditCus] = useState(false);
  const [oldEditCusId, setOldEditCusId] = useState(0);
  const [bankIdData, setBankIdData] = useState(null);
  const [isComexSettingStatus, setIsComexSettingStatus] = useState(null);
  const formatTwoDigits = digit => ('0' + digit).slice(-2);
  const tempDate = new Date();
  // const today_date = `${formatTwoDigits(tempDate.getDate())}/${formatTwoDigits(tempDate.getMonth() + 1)}/${tempDate.getFullYear()}`;
const today_date = `${tempDate.getFullYear()}/${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}`;

// Get the current time components
const hours = formatTwoDigits(tempDate.getHours());
const minutes = formatTwoDigits(tempDate.getMinutes());
const seconds = formatTwoDigits(tempDate.getSeconds());

const date = `${tempDate.getFullYear()}/${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}`;
// Concatenate the date and time components together
const today_date_with_time = `${today_date} ${hours}:${minutes}:${seconds}`;
  const [open, setOpen] = React.useState(false);
  const [openCreateQuotationDialog, setOpenCreateQuotationDialog] = React.useState(false);
  const [createBtnClicked, setCreateBtnClicked] = useState(false);
  const [hideCustomer, setHideCustomer] = React.useState(true);
  const [clickedSearchCustomer, setClickedSearchCustomer] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState(true);
  const [errorEmail, setErrorEmail] = React.useState(true);
  const [requiredEmail, setRequiredEmail] = React.useState(true);
  const [limitCusName, setLimitCusName] = useState(true);
  const [errorSearchNationalId, setErrorSearchNationalId] = React.useState(true);
  const comexSettingsEntity = useAppSelector(state => state.comexSettings.entity);
  const filterCurrency = currencyRateList.filter(c => c.buyFrom.name === currencyName)[0];
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false);
  const [isSaveButtonClicked2, setIsSaveButtonClicked2] = useState(false);
  const [errMsgCurrency, setErrMsgCurrency] = useState('');
  const [operationTime, setOperationTime] = useState(true);
  const [loadingSpinner, setLoadingSpinner] = useState(true);
  const [validationForCommodity, setValidationForCommodity] = useState(true);
  const [operationStartTimeDisplay, setOperationStartTimeDisplay] = useState('');
  const [operationEndTimeDisplay, setOperationEndTimeDisplay] = useState('');
  const [errorName, setErrorName] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [preventMultipleClick, setPreventMultipleClick] = useState(false);
  const [convertLimitAmount, setConvertLimitAmount] = useState(0);
  const dateAndTime = moment(quotationEntity?.createdDate).format('yyyy/MM/DD HH:mm:ss');


  const currentDate = `${formatTwoDigits(tempDate.getMonth() + 1)}/${formatTwoDigits(tempDate.getDate())}/${tempDate.getFullYear()}`;
  //   const [kuwaitDinarCurrencyIndexId, setKuwaitDinarCurrencyIndexId] = useState(0);
  //   const [malaysiaRinggitCurrencyIndexId, setMalaysiaRinggitCurrencyIndexId] = useState(0);
  //   const [kwdToMyrExchangeRate, setKwdToMyrExchangeRate]=useState(0);
  const bankFeeInMYRList = [] as Array<IComexBankFee>;
 // const userLocalStart = moment.tz(FRIDAY_PRAYER_START_TIME, 'HH:mm:ss', 'Asia/Kuala_Lumpur').local().format('hh:mm A');

  const [itemFee, setItemFee] = useState(0);
  const [commissionPrice,setCommissionPrice]=useState(0);
  const [itemTotalAmount, setItemTotalAmount] = useState(0);
  const [bankFeeLastIndex, setBankFeeLastIndex] = useState(0);
  const [localStart, setLocalStart] = React.useState<any>(new Date('2018-01-01T19:30:00.000Z'));
  const [localEnd, setLocalEnd] = React.useState<any>(new Date('2018-01-01T17:29:00.000Z'));
  const [transactionFee, setTransactionFee] = useState(0);
  const [transactionFeeInQuotationCurrency, setTransactionFeeInQuotationCurrency] = useState(0);

  const emailExist = customers?.filter(c => c?.email === cusEmail);
  const icExist = customers?.filter(c => c?.ic === cusIc);
  const newCustWithExistingEmail = (icExist?.length === 0 && emailExist?.length > 0 && cusEmail !== '');
  const numFormatter = Intl.NumberFormat('en-US');
  const [errorPhoneExist, setErrorPhoneExist] = useState('');

  const checkPhoneNumber = async (phoneNumber, customerIc) => {
    try {

      const result = await dispatch(verifyExistingCustomerPhoneNumber({ phoneNumber, customerIc }));

      if (result.meta.requestStatus === 'rejected') {
        setErrorPhoneExist('Error checking phone number');
      } else if (result.meta.requestStatus === 'fulfilled') {
        if (result.payload === true) {
          setErrorPhoneExist(translate('register.messages.error.phoneexists'));
        } else {
          setErrorPhoneExist('');
        }
      }
    } catch (error) {
      console.error('Error checking phone number:', error);
      setErrorPhoneExist('Error checking phone number');
    }
  };

  useEffect(() => {
    if (phone) {
      checkPhoneNumber(phone, cusIc);
    }
  }, [phone, cusIc]);

  const handleClickToOpen = () => {
    setOpen(true);
 //   setOpenCreateQuotationDialog(true);
  };
  const handleToClose = () => {
    setOpen(false);
 //   setOpenCreateQuotationDialog(false);
  };
  const [value, setValue]: any = useState();
  const handleClose = () => {
    setOpenCreateQuotationDialog(false);
    if (isNew) {
      historyBack.push('/home');
    } else {
      props.history.push(`/quotation/purchase/${props.match.params.id}`);
    }
  };
  const validateCurrency = () => {
    setOpenCreateQuotationDialog(true)
    setIsSaveButtonClicked(true);
    if (filterCurrency?.buyFrom?.active === false) {
      setErrMsgCurrency(translate('comexApp.quotation.currencyIsInactive'));
    } else {
      setErrMsgCurrency('');
    }
  };

  useEffect(() => {
    if (Storage.session.get('locale') === 'ar-ly' && itemDescription !== '' && itemDescription !== undefined) {
      const data = {
        q: itemDescription,
        source: 'en',
        target: 'ar',
        api_key: '',
      };
      axios.post(`https://translate.argosopentech.com/translate`, data).then(response => {
        setItemDescriptionInArabic(response?.data?.translatedText);
      });
    }
  }, [itemDescription]);


  useEffect(() => {
    if (bankIdData) {
      dispatch(getFeeListByBankId(bankIdData));
    }
  }, [bankIdData]);

  useEffect(() => {
    // setBankFeeLastIndex(bankFeeList.length - 1);
    localStorage.setItem('print', 'false');
  }, [bankFeeList]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      setClickedSearchCustomer(true);

      dispatch(getEntity(props.match.params.id)).then(res => {
        let newItemQuantity = (res.payload['data'].amount * res.payload['data'].currencyRate) / res.payload['data'].commodity.price;
        newItemQuantity = Math.round(newItemQuantity * 100) / 100;
        setItemName(res.payload['data']?.commodity?.name);
        setAmount(res.payload['data'].amount);
        setAmountDisplayed(res.payload['data'].amount);

        const feeCurrency = res.payload['data']?.bank?.feeCurrency ?? '';
        setCurrencyName(feeCurrency.toLowerCase() === 'bank default currency'.toLowerCase() ? res.payload['data'].currencyIndex.name || '' : res.payload['data'].feeCurrencyIndex || '');

        setItemDescription(res.payload['data'].commodity.description);
        setItemPrice(res.payload['data'].commodity.price);
        setItemQuantity(newItemQuantity);
        setItemFee(res.payload['data'].fee);
        setCommissionPrice(res.payload['data'].commission);
        setItemTotalAmount(res.payload['data'].totalAmount);
        setCusName(res.payload['data'].customer.name);
        setCusIc(res.payload['data'].customer.ic);
        setCusIdSearch(res.payload['data'].customer.ic);
        setNationality(res.payload['data'].customer.nationality);
        setCountryCode(countryList.find(country => country.Desc_en === bankEntity?.country)?.code.toLowerCase());
        setPhone(res.payload['data'].customer.tel);
        setCusEmail(res.payload['data'].customer.email);
        setBankIdData(res.payload['data']?.bank?.id);
      });
    }
    dispatch(getCurrencies({}));
    dispatch(getAccount());
    dispatch(getCurrencyRates({}));
    dispatch(getBankByBranchId(account?.branchId));
    dispatch(getBranch(account?.branchId));
    dispatch(getCustomers({}));
    const getComexSettingDispatch = async () => {
      const result = await dispatch(getComexSetting(1));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (result.payload && result.payload?.status === 200) {
        setIsComexSettingStatus(200);
      }
    };
    getComexSettingDispatch();
  }, []);

  useEffect(() => {
    if (account?.login !== undefined && comexSettingsEntity.serverStatus && isComexSettingStatus === 200) {
      const operationStartMalayTime = moment(comexSettingsEntity?.operationStartTime).format('HH:mm:ss');
      const startDateTime = new Date(currentDate + ' ' + operationStartMalayTime);
      const currDate = new Date();
      const startHourMin = operationStartMalayTime.split(':');
      if (parseInt(startHourMin[0], 10) > currDate.getHours()) startDateTime.setDate(startDateTime.getDate() - 1);
      const startTime = Date.parse(startDateTime.toString());
      localStart.setHours(startHourMin[0]);
      localStart.setMinutes(startHourMin[1]);
      const operationEndMalayTime = moment(comexSettingsEntity?.operationEndTime).format('HH:mm');
      const currentDateTime = Date.now();
      const endHourMin = operationEndMalayTime.split(':');

      const endDateTime = new Date(currentDate + ' ' + operationEndMalayTime);
      if (parseInt(endHourMin[0], 10) <= currDate.getHours()) endDateTime.setDate(endDateTime.getDate() + 1);

      const endTime = Date.parse(endDateTime.toString());
      localEnd.setHours(endHourMin[0]);
      localEnd.setMinutes(endHourMin[1]);
      const currentTime = moment().format('HH:mm').split(':');

      if (startTime <= currentDateTime && currentDateTime <= endTime) setOperationTime(true);
      else setOperationTime(false);
    }
  }, [comexSettingsEntity, isComexSettingStatus]);
  useEffect(() => {
    if (bankEntity?.id !== undefined) {
      setBankIdData(bankEntity?.id)
    }

  }, [bankEntity]);

  useEffect(() => {

    if ( bankEntity!==null && bankEntity!==undefined ) {

      dispatch(getCommoditiesByBankId(bankEntity?.id));
      setBankIdData(bankEntity?.id);
      if(isNew) {
        setCurrencyName(bankEntity?.currencyIndex?.name);
      }

      const feeCurrency = bankEntity?.feeCurrency ?? '';
      console.warn (feeCurrency);
      setIsDefaultCurrency(feeCurrency.toLowerCase() === 'bank default currency'.toLowerCase());
      if (feeCurrency.toLowerCase() === 'bank default currency'.toLowerCase()) {
        setDisplayCurrencyName(bankEntity?.currencyIndex?.name);
      } else {
        setDisplayCurrencyName(currencyName);
      }
    }
  }, [bankEntity]);

  useEffect(() => {
    const feeCurrency = bankEntity?.feeCurrency ?? '';
    if (feeCurrency && feeCurrency.toLowerCase() === 'bank default currency'.toLowerCase()) {
      setDisplayCurrencyName(bankEntity?.currencyIndex?.name);
    } else {
      setDisplayCurrencyName(currencyName);
    }
  }, [currencyName]);

  useEffect(() => {
    if (updateSuccess) {
        if (isNew) {
            setOpenCreateQuotationDialog(true);
        } else {
            handleClose();
        }
    } else {
      setOpenCreateQuotationDialog(false);
    }
}, [updateSuccess, isNew, openCreateQuotationDialog]);


  useEffect(() => {
    const selectedItem = commodities?.find(element => element?.name === itemName);
    const selectedCurrencyRate = currencyRateList?.find(element => element?.buyFrom?.name === currencyName);

    if (selectedItem) {
      setItemDescription(selectedItem.description);
      setItemDescriptionInArabic(selectedItem.description);
      let newItemQuantity = 0;
      if (selectedCurrencyRate) {
        newItemQuantity = (amountV * selectedCurrencyRate.exchangeRate) / selectedItem.price;
        newItemQuantity = Math.round(newItemQuantity * 100) / 100;
        setItemQuantity(newItemQuantity);

        const itemPriceValue = selectedItem.price / selectedCurrencyRate.exchangeRate;
        const truncatedPrice = Math.round(itemPriceValue * 100) / 100;
        setItemPrice(truncatedPrice);

      }
      if(newItemQuantity > selectedItem?.stock) {
        setValidationForCommodity(false);
      }
      else{
        setValidationForCommodity(true);
      }
    }

    let kuwaitDinarCurrencyIndexId = 0;
    let malaysiaRinggitCurrencyIndexId = 0;
    let kwdToMyrExchangeRate = 0;
    let selectedToMyrExchangeRate = 0;
    let selectedCurrencyIndexId = 0;
    let filteredBankFeeList;
    let filteredBankFeeData;
    let filteredBankFeeListDefaultCurrency;
    let filteredBankDataInQuotationCurrency;

    if (currencyIndexList.length > 0) {
      selectedCurrencyIndexId = currencyIndexList.filter(currencyIndex => currencyIndex.name === currencyName)[0]?.id;
      kuwaitDinarCurrencyIndexId = currencyIndexList.filter(currencyIndex => currencyIndex.name === bankEntity?.currencyIndex?.name)[0]?.id;
      malaysiaRinggitCurrencyIndexId = currencyIndexList.filter(currencyIndex => currencyIndex.name === 'MYR')[0]?.id;
      kwdToMyrExchangeRate = currencyRateList.filter(
        currencyRate =>
          currencyRate.buyFrom?.id === kuwaitDinarCurrencyIndexId && currencyRate.sellTo?.id === malaysiaRinggitCurrencyIndexId
      )[0]?.exchangeRate;

      filteredBankFeeList = bankFeeList.filter(fee => fee?.currencyId === selectedCurrencyIndexId
          && fee?.bankId === bankEntity?.id
          && fee?.feeType?.toLowerCase() === 'commission'
      );

      filteredBankFeeListDefaultCurrency = bankFeeList.filter(fee => fee?.currencyId === bankEntity?.currencyIndex?.id
        && fee?.bankId === bankEntity?.id
        && fee?.feeType?.toLowerCase() === 'commission'
      );

      if (isDefaultCurrency) {
        filteredBankFeeData = filteredBankFeeListDefaultCurrency[0];
        filteredBankDataInQuotationCurrency =  filteredBankFeeList[0];
      } else {
        filteredBankFeeData = filteredBankFeeList[0];
      }
    }

    if (selectedCurrencyIndexId > 0 && kuwaitDinarCurrencyIndexId > 0 && selectedCurrencyIndexId !== malaysiaRinggitCurrencyIndexId) {
      selectedToMyrExchangeRate = currencyRateList.filter(
        currencyRate => currencyRate.buyFrom?.id === selectedCurrencyIndexId && currencyRate.sellTo?.id === malaysiaRinggitCurrencyIndexId
      )[0]?.exchangeRate;
    } else {
      selectedToMyrExchangeRate = 1;
    }

    const filteredBankFeeCommisionList = bankFeeList.filter(fee =>
        (fee?.currencyId === selectedCurrencyIndexId && fee?.bankId === bankEntity?.id && fee?.feeType?.toLowerCase() === 'lump sum') ||
        (fee?.bankId === bankEntity?.id && fee?.feeType?.toLowerCase() === 'percentage')
    );

    const filteredBankFeeCommisionDefaultCurrencyList = bankFeeList.filter(fee =>
      (fee?.currencyId === bankEntity?.currencyIndex?.id && fee?.bankId === bankEntity?.id && fee?.feeType?.toLowerCase() === 'lump sum') ||
      (fee?.bankId === bankEntity?.id && fee?.feeType?.toLowerCase() === 'percentage')
    );

    const relevantFeeList = isDefaultCurrency ? filteredBankFeeCommisionDefaultCurrencyList : filteredBankFeeCommisionList;

    const bankFeeCommissionLastIndex = relevantFeeList.length - 1;
    const amountVInMYR = Math.round(amountV * selectedToMyrExchangeRate * 100) / 100;

    const amountVInBankDefault = Math.round((amountVInMYR / kwdToMyrExchangeRate) * 100) / 100;

    let temp_fee = 0;
    if(!bankEntity?.commission) {
      for (let i = relevantFeeList.length - 1; i >= 0; i--) {

        const tempLastIndexBankFeeValue = relevantFeeList[bankFeeCommissionLastIndex]?.fromTo;

        if (i !== 0) {
          if (i === bankFeeCommissionLastIndex && amountV > tempLastIndexBankFeeValue) {
            const type = relevantFeeList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee = Math.round(relevantFeeList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (relevantFeeList[i].amount * 0.01)
              if(isDefaultCurrency){
                temp_fee = Math.round(amountVInBankDefault * percentage);
              } else {
                temp_fee = Math.round(amountV * percentage);
              }
            }
          } else if (
              amountV <= relevantFeeList[i]?.fromTo &&
              amountV > relevantFeeList[i - 1]?.fromTo
          ) {
            const type = relevantFeeList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee = Math.round(relevantFeeList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (relevantFeeList[i].amount * 0.01)
              if(isDefaultCurrency){
                temp_fee = Math.round(amountVInBankDefault * percentage);
              } else {
                temp_fee = Math.round(amountV * percentage);
              }
            }
            //                        break;
          }
        } else if (i === 0) {
          if (i === bankFeeCommissionLastIndex && amountV > tempLastIndexBankFeeValue) {
            const type = relevantFeeList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee = Math.round(relevantFeeList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (relevantFeeList[i].amount * 0.01)
              if(isDefaultCurrency){
                temp_fee = Math.round(amountVInBankDefault * percentage);
              } else {
                temp_fee = Math.round(amountV * percentage);
              }
            }
          } else if (amountV <= relevantFeeList[i]?.fromTo) {
            const type = relevantFeeList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee = Math.round(relevantFeeList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (relevantFeeList[i].amount * 0.01)
              if(isDefaultCurrency){
                temp_fee = Math.round(amountVInBankDefault * percentage);
              } else {
                temp_fee = Math.round(amountV * percentage);
              }
            }
            //                          break;
          }
        } else {
          temp_fee = 0.0;
        }
      }
    }

    const bankFeeCommissionTransactionCurrencyLastIndex = filteredBankFeeCommisionList.length - 1;
    let temp_fee_in_transaction_currency = 0;

    if(!bankEntity?.commission) {
      if(isDefaultCurrency){
      for (let i = filteredBankFeeCommisionList.length - 1; i >= 0; i--) {

        const tempLastIndexBankFeeValue = filteredBankFeeCommisionList[bankFeeCommissionTransactionCurrencyLastIndex]?.fromTo;

        if (i !== 0) {
          if (i === bankFeeCommissionTransactionCurrencyLastIndex && amountV > tempLastIndexBankFeeValue) {
            const type = filteredBankFeeCommisionList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee_in_transaction_currency = Math.round(filteredBankFeeCommisionList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (filteredBankFeeCommisionList[i].amount * 0.01);
              temp_fee_in_transaction_currency = Math.round(amountV * percentage);
            }
          } else if (
            amountV <= filteredBankFeeCommisionList[i]?.fromTo &&
            amountV > filteredBankFeeCommisionList[i - 1]?.fromTo
          ) {
            const type = filteredBankFeeCommisionList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee_in_transaction_currency = Math.round(filteredBankFeeCommisionList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (filteredBankFeeCommisionList[i].amount * 0.01);
              temp_fee_in_transaction_currency = Math.round(amountV * percentage);
            }
            //                        break;
          }
        } else if (i === 0) {
          if (i === bankFeeCommissionTransactionCurrencyLastIndex && amountV > tempLastIndexBankFeeValue) {
            const type = filteredBankFeeCommisionList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee_in_transaction_currency = Math.round(filteredBankFeeCommisionList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (filteredBankFeeCommisionList[i].amount * 0.01);
              temp_fee_in_transaction_currency = Math.round(amountV * percentage);
            }
          } else if (amountV <= filteredBankFeeCommisionList[i]?.fromTo) {
            const type = filteredBankFeeCommisionList[i].feeType;
            if (type === 'Lump Sum')
              temp_fee_in_transaction_currency = Math.round(filteredBankFeeCommisionList[i].amount);
            else if (type === 'Percentage') {
              const percentage = (filteredBankFeeCommisionList[i].amount * 0.01);
              temp_fee_in_transaction_currency = Math.round(amountV * percentage);
            }
            //                          break;
          }
        } else {
          temp_fee_in_transaction_currency = 0.0;
        }
      }
      }
    }

    let commission = 0;
    let commissionInTransactionCurrency = 0;
      if(bankEntity?.commission){

        if(amountV>0 && filteredBankFeeData?.fromTo>0 && filteredBankFeeData?.amount>0){

          // console.warn("amount by commission"+Math.trunc((amountV /((filteredBankFeeData?.fromTo* kwdToMyrExchangeRate)/selectedToMyrExchangeRate))));
          console.warn("amount by commission"+Math.trunc((amountV)));

          // commission= Math.ceil(Math.ceil(amountV /((bankEntity?.commissionForEachAmount* kwdToMyrExchangeRate)/selectedToMyrExchangeRate))*((bankEntity?.commissionAmount* kwdToMyrExchangeRate)/selectedToMyrExchangeRate));
          if(filteredBankFeeData!==undefined) {
            // commission = Math.ceil(Math.ceil(amountV / ((filteredBankFeeData?.fromTo * kwdToMyrExchangeRate) / selectedToMyrExchangeRate)) * (filteredBankFeeData?.amount));
            // commission = Math.ceil(Math.ceil(amountV / (filteredBankFeeData?.fromTo)) * (filteredBankFeeData?.amount));
            commission = Math.round(Math.ceil(amountV / (filteredBankFeeData?.fromTo)) * (filteredBankFeeData?.amount));
          }

          setCommissionPrice(commission);
          const temp_totalAmount = Number(amountV) + commission;
          setItemTotalAmount(Math.round(temp_totalAmount));

          // calculate transaction Fee
          const calculatedTransactionFee =  commission;
          setTransactionFee(Math.round(calculatedTransactionFee));
          setTransactionFeeInQuotationCurrency(Math.round(calculatedTransactionFee));
        }else{

          setCommissionPrice(0);
          const temp_totalAmount = Number(amountV) ;
          setItemTotalAmount(Math.round(temp_totalAmount));

          // calculate transaction Fee
          const calculatedTransactionFee =  commission;
          setTransactionFee(Math.round(calculatedTransactionFee));
          setTransactionFeeInQuotationCurrency(Math.round(calculatedTransactionFee));
        }

        if (isDefaultCurrency) {
          // set Fee in transaction currency if fee_currency = Bank default currency
          if (amountV > 0 && filteredBankDataInQuotationCurrency?.fromTo > 0 && filteredBankDataInQuotationCurrency?.amount > 0) {
            if (filteredBankDataInQuotationCurrency !== undefined) {
              // commissionInTransactionCurrency = Math.ceil(Math.ceil(amountV / (filteredBankDataInQuotationCurrency?.fromTo)) * (filteredBankDataInQuotationCurrency?.amount));
              commissionInTransactionCurrency = Math.round(Math.ceil(amountV / (filteredBankDataInQuotationCurrency?.fromTo)) * (filteredBankDataInQuotationCurrency?.amount));
            }
          }
          // calculate transaction Fee in quotation currency
          const calculatedTransactionFeeInQuotationCurrency = commissionInTransactionCurrency;
          setTransactionFeeInQuotationCurrency(Math.round(calculatedTransactionFeeInQuotationCurrency));
        }

      }
      else{

        setItemFee(Math.round(temp_fee));
        setCommissionPrice(0);
        const temp_totalAmount = Number(amountV) + temp_fee;
        setItemTotalAmount(Math.round(temp_totalAmount));

        // calculate transaction Fee
        const calculatedTransactionFee =  temp_fee;
        setTransactionFee(Math.round(calculatedTransactionFee));
        setTransactionFeeInQuotationCurrency(Math.round(calculatedTransactionFee));

        if (isDefaultCurrency) {
          // calculate transaction Fee in quotation currency
          const calculatedTransactionFeeInQuotationCurrency = temp_fee_in_transaction_currency;
          setTransactionFeeInQuotationCurrency(Math.round(calculatedTransactionFeeInQuotationCurrency));
        }
      }


    // conversion for transaction amount limit
    const bankDefaultCurrencyRates = currencyRateList?.find(element => element.buyFrom.name === bankEntity?.currencyIndex?.name);
    const toMyr = bankEntity?.transactionAmountLimit * bankDefaultCurrencyRates?.exchangeRate;
    if (currencyName === bankEntity?.currencyIndex?.name) {
      setConvertLimitAmount(bankEntity?.transactionAmountLimit);
    } if (currencyName === 'MYR') {
      setConvertLimitAmount(toMyr);
    } if (currencyName !== bankEntity?.currencyIndex?.name && currencyName !== 'MYR') {
      const toOtherCurrency = toMyr / selectedCurrencyRate?.exchangeRate;
      setConvertLimitAmount(toOtherCurrency);
    }

  }, [currencyName, itemName, amountV, bankFeeList]);


  useEffect(() => {
    if (cusIdSearch) {
      customerSearch();
    }
  }, [cusIdSearch]);

  const handlerCurrency = event => {
    const selectedCurrency = event.target.value;
    setCurrencyName(selectedCurrency);
  };

  const handlerItem = event => {
    const selectedCommodity=event.target.value;
    setItemName(selectedCommodity);
  };

  const handlerNationality = event => {
    if (Storage.session.get('locale') === 'en') {
      const selectedCountry = event.target.value;
      setNationality(selectedCountry);
      const countryIndex = countryList.find(c => c.Desc_en === selectedCountry);
      setNationalityArabic(countryIndex['Desc_ar']);
    } else {
      const selectedCountry = event.target.value;
      setNationalityArabic(selectedCountry);
      const countryIndex = countryList.find(c => c.Desc_ar === selectedCountry);
      setNationality(countryIndex['Desc_en']);
    }
  };
  function toEnglishDigits(str) {
    let e = '۰'.charCodeAt(0);

    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    // str = str.replace(/[۰-۹]/g, function(t) {
    //   return t.charCodeAt(0) - e;
    // });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  }
  const handlerAmount = event => {
    const amount = event.target.value.replace(/,/g, '');
    setAmount(toEnglishDigits(amount));
    const selectedAmount = toEnglishDigits(amount).toLocaleString();
    setAmountDisplayed(selectedAmount);

  };

  // Remove characters (if any) from Amount Input when the user clicks or focusOut of the amount input
  const handleDigits = event => {
    const amount = event.target.value.replace(/[^0-9.]+/g, "");
    setAmount(amount);
    const formattedAmount = Number(amount).toLocaleString();
    setAmountDisplayed(formattedAmount);

  }


  const [disable, setDisable] = useState(true);


  const handlePhoneNumberDefaultCountryCode = () => {
    // if (!isNew) return 'us';
    const bankCountryName = bankEntity?.country;
    const defaultCode = countryList.find(country => country.Desc_en === bankCountryName)?.code.toLowerCase();
    return defaultCode
  };

  const handlePhone = (...args) => {
    setPhone(args[3]);
  };

  const handleBack = () => {
    if (isNew) {
      props.history.push(`/home`);
    } else {
      historyBack.goBack();
    }
  };

  function customerSearch() {
    let found: ICustomer;
    const entity: ICustomerQueryParam = {
      ic: cusIc,
      bId: isNew ? bankEntity?.id : quotationEntity?.bank?.id,
    };
    dispatch(getEntityByIcAndBankId(entity)).then(res => {
      found = res?.payload['data'];

      if (Object?.keys(found)?.length === 0) {
        setNewEditCus(true);
        setCusName('');
        setNationality('');
        setPhone(countryCode);
        setCountryCode(handlePhoneNumberDefaultCountryCode());
        setCusEmail('');
        setOldEditCusId(null);
        setDisable(false);
        setOpen(true);
        fillOptionalEmail();
        //       alert(translate('comexApp.quotation.notFoundAlert'));
      } else {
        setCusName(found?.name);
        setNationality(found?.nationality);
        setPhone(found?.tel);
        setCusEmail(found?.email);
        dispatch(getVerifyExistingEmailUser(found?.email));
        setOldEditCusId(found?.id);
        // setDisable(true);
        setDisable(false);
      }
      setHideCustomer(false);
    });

    setErrorSearchNationalId(true);
    setClickedSearchCustomer(true);
  }
  useEffect(() => {
    const cusNameCheck = cusName.length <= 100;
    if(cusNameCheck === false ){
      setLimitCusName(false);
    }
    else {
      setLimitCusName(true);
    }
  });

  const saveCustomer = async () => {
    setLimitCusName(true);
    setRequiredEmail(true);
    setErrorEmail(true);
    setErrorPhone(true);
    setPreventMultipleClick(true);
    let chosenCustomer: ICustomer;
    if (clickedSearchCustomer) {
      const phoneCheck = phone !== "" && phone !== null && phone !== undefined && phone !== countryCode && phone.length > 3;
      const cusNameCheck = cusName.length <= 100;
      const emailRequiredCheck= cusEmail.replace(' ','')==='';
      const emailCheck= cusEmail.replace(' ','')!=='' && (verifyEmailUser?.id=== undefined  || cusIc===verifyEmailUser?.login);
      const emailValue = cusEmail === "" ? `alshmokhsender+${cusIc}@gmail.com` : cusEmail;
      const amountLimitCheck = amountV > convertLimitAmount;
      const errorPhoneExistCheck = errorPhoneExist === '';
      if (phoneCheck && cusNameCheck && !newCustWithExistingEmail && !amountLimitCheck && errorPhoneExistCheck) {
        if (isNew) {
          const entity: ICustomerQueryParam = {
            ic: cusIc,
            bId: bankIdData,
          };
          const response = await dispatch(getEntityByIcAndBankId(entity));
          const found = response.payload['data'];
          if (Object.keys(found).length === 0) {
            setNewEditCus(true);
            const newCustomer = {
              ic: cusIc,
              name: cusName,
              nationality: cusNationality,
              tel: phone,
              email: emailValue,
              bankId: bankIdData,
            };
            const res = await dispatch(createCustomer(newCustomer));
            chosenCustomer = res.payload['data'];
          } else {
            setOldEditCusId(found.id);
            const updateExistingCustomer = {
              ...found,
              id: found.id,
              ic: cusIc,
              name: cusName,
              nationality: cusNationality,
              tel: phone,
              email: emailValue,
              bankId: bankIdData,
            };
            const updateExCustomer = JSON.stringify(found) === JSON.stringify(updateExistingCustomer);
            if (!updateExCustomer) {
              const res = await dispatch(updateCustomer(updateExistingCustomer));
              chosenCustomer = res.payload['data'];
            } else {
              chosenCustomer = found;
            }
          }
        } else {
          const entity: ICustomerQueryParam = {
            ic: cusIc,
            bId: quotationEntity?.bank?.id,
          };
          const response = await dispatch(getEntityByIcAndBankId(entity));
          const found = response.payload['data'];
          if (Object.keys(found).length === 0) {
            setNewEditCus(true);
            const newCustomer = {
              ic: cusIc,
              name: cusName,
              nationality: cusNationality,
              tel: phone,
              email: emailValue,
              bankId: bankIdData,
            };
            const res = await dispatch(createCustomer(newCustomer));
            chosenCustomer = res.payload['data'];
          } else {
            setOldEditCusId(found.id);
            const updateExistingCustomer = {
              ...found,
              id: found.id,
              ic: cusIc,
              name: cusName,
              nationality: cusNationality,
              tel: phone,
              email: emailValue,
              bankId: bankIdData,
            };
            const updateExCustomer = JSON.stringify(found) === JSON.stringify(updateExistingCustomer);
            if (!updateExCustomer) {
              const res = await dispatch(updateCustomer(updateExistingCustomer));
              chosenCustomer = res.payload['data'];
            } else {
              chosenCustomer = found;
            }
          }
        }
        const isEditCustomer = JSON.stringify(chosenCustomer) === JSON.stringify(quotationEntity.customer);
        const quotationValues = {
          ...quotationEntity,
          amount: +amountV,
          fee: itemFee,
          commission:commissionPrice,
          totalAmount: itemTotalAmount,
          description: itemDescription,
          descriptionInArabic: itemDescriptionInArabic,
          commodityUnitPrice: itemPrice,
          commodityQuantity: itemQuantity,
          currencyRate: currencyRateList?.find(currencyRate => currencyRate.buyFrom.name === currencyName)?.exchangeRate,
          buyCurrency: currencyName,
          branchId: branchEntity?.id,
          branch: branchEntity,
          customerId: chosenCustomer.id,
          customer: chosenCustomer,
          commodityId: commodities?.find(commodity => commodity.name === itemName)?.id,
          commodity: commodities?.find(commodity => commodity.name === itemName),
          currencyIndexId: currencyRateList?.find(currencyRate => currencyRate.buyFrom.name === currencyName)?.buyFrom?.id,
          currencyIndex: currencyRateList?.find(currencyRate => currencyRate.buyFrom.name === currencyName)?.buyFrom,
          bankManagerApproval: null,
          bankCustomerApproval: null,
          smsLanguage: null,
          status: 'Quoted',
          feeCurrencyIndex:displayCurrencyName,
          feeInQuotationCurrency:transactionFeeInQuotationCurrency,
        };
        const customerEditQuo = { ...quotationValues, customerId: oldEditCusId };
        if (!isNew) {
          if (!isEditCustomer && newEditCus) {
            setLoadingSpinner(false);
            setIsSaveButtonClicked2(true);
          }
          setLoadingSpinner(false);
          setIsSaveButtonClicked2(true);
          if(preventMultipleClick === false) {

            await dispatch(updateEntity(quotationValues));
          }
        } else {
          setLoadingSpinner(false);
          setIsSaveButtonClicked2(true);
          if(preventMultipleClick === false){
            await dispatch(createEntity(quotationValues));
          }
        }
      }

       if (cusNameCheck === false) {
        setLimitCusName(false);
        setPreventMultipleClick(false);
      }
       // if (emailRequiredCheck === true) {
       //  setRequiredEmail(false);
       //  setPreventMultipleClick(false);
      // }
       if (newCustWithExistingEmail) {
        setErrorEmail(false);
        setPreventMultipleClick(false);
      }
       if (phoneCheck === false) {
        setErrorPhone(false);
        setPreventMultipleClick(false);
      }
       if (amountLimitCheck === true) {
        setPreventMultipleClick(false);
      }
      if (errorPhoneExistCheck === false) {
        setPreventMultipleClick(false);
      }
    } else {
      setErrorSearchNationalId(false);
    }
  };



  const checkExistingEmail = (emailCheck) => {
    if(emailExist?.length > 0){
      dispatch(getVerifyExistingEmailUser(emailCheck));
      setCusEmail(emailCheck);
      // setRequiredEmail(true);
    }
    else{
      setCusEmail(emailCheck);
      // setRequiredEmail(false);
      setErrorEmail(true);
    }
  }

  const fillOptionalEmail = () => {
    // You can modify the value of input1Value here as needed
    const optionalEmail = 'alshmokhsender+' + cusIc + '@gmail.com';

    // Set the modified value to Input2
    setCusEmail(optionalEmail);
  };

  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);
  return (
    <>
      <Dialog open={open} onClose={handleToClose}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }} className="mb-3">
            {translate('comexApp.quotation.customerNotFound')}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mx-3">
          <Button color="primary" onClick={handleToClose} autoFocus>
            {translate('comexApp.quotation.okButton')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openCreateQuotationDialog} onClose={handleClose} hidden={!isNew}>
        <DialogTitle></DialogTitle>
        <DialogContent>
          <DialogContentText style={{ color: 'black' }} className="mb-3">

          {translate('comexApp.quotation.quotationCreate', {
    quotationNo: <b>{quotationEntity?.id?.toString()}</b>,
    date: <b>{moment(quotationEntity?.createdDate).local().format('yyyy/MM/DD HH:mm:ss') || ''}</b>
  })}
          </DialogContentText>
          <DialogContentText>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="mx-3">
          <Button color="primary" onClick={handleClose} autoFocus>
            {translate('comexApp.quotation.okButton')}
          </Button>
        </DialogActions>
      </Dialog>

      <div className="overlay" hidden={loadingSpinner}>
        <img
          src="../../content/images/loading.gif"
          style={{ alignContent: 'center', position: 'fixed', left: '46%', top: '40%', zIndex: '1000' }}
          hidden={loadingSpinner}
        />
      </div>
      &nbsp;
      <Row className="justify-content-center">
        <Col md="8" lg="6">
          <ThemeProvider theme={responsiveFont}>
            <Typography variant="h4" component="div">
              {translate('comexApp.quotation.home.createOrEditLabel')}
            </Typography>
          </ThemeProvider>
        </Col>

        <Row className="justify-content-center" style={{ marginTop: '12px' }}>
          <Col md="8" lg="6" className="round-border mt-1" style={{ padding: '28px 42px 28px 42px' }}>
            {loading ? (
              <h5>
                <Translate contentKey={'global.loading'} />
              </h5>
            ) : (
              <>
                <ThemeProvider theme={responsiveFont}>
                  <Typography variant="h6" component="div">
                    <strong>{translate('comexApp.quotation.orderInfoLabel')}</strong>
                  </Typography>
                </ThemeProvider>
                &nbsp;
                <ValidatedForm onSubmit={saveCustomer}>
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.date')}</Label>
                    </div>
                    <div className="col-sm-9 box-space">
  <ValidatedField
    id="date"
    name="date"
    data-cy="date"
    value={
      (() => {
        if (quotationEntity?.lastModifiedDate) {
          return moment(quotationEntity?.lastModifiedDate).local().format('yyyy/MM/DD HH:mm:ss');
        } else {
          return moment(quotationEntity?.createdDate).local().format('yyyy/MM/DD HH:mm:ss');
        }
      })()
    }
    disabled
  />
</div>
                  </div>
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.amount')}</Label>
                      <Label style={{ color: 'red' }}>*</Label>
                    </div>
                    <div
                      className="row col-sm-9"
                      style={
                        Storage.session.get('locale') === 'en'
                          ? { paddingRight: '0px', paddingLeft: '24px' }
                          : { paddingRight: '24px', paddingLeft: '0px' }
                      }
                    >
                      <div className="col-5 col-sm-3 px-0 box-space">
                        <ValidatedField
                          id="currency_name_option"
                          name="currency_name"
                          data-cy="currency_name"
                          type="select"
                          onChange={handlerCurrency}
                          value={currencyName}
                          required={filterCurrency?.buyFrom?.active === false}
                        >
                          <option key="0" />
                          {currencyRateList
                            ? currencyRateList
                                .filter(item => item.buyFrom.active)
                                .map(item => (
                                  <option value={item.buyFrom.name} key={item.id} disabled={item.buyFrom.active === false}>
                                    {item.buyFrom.name}
                                  </option>
                                ))
                            : null}
                        </ValidatedField>
                      </div>
                      <div className="col-7 col-sm-9 px-0 box-space">
                        <ValidatedField
                          id="amount"
                          name="amount"
                          data-cy="amount"
                          type={'text'}
                          onChange={handlerAmount}
                          onBlur={handleDigits}
                          value={amountDisplayed}
                          required
                          min={1}
                          style={{
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          }}
                        />
                      </div>
                      {amountV > convertLimitAmount && (
                        <Label className="p-0" style={{ fontSize: 14, color: 'red', fontWeight: 400 }}>
                          {translate('comexApp.quotation.amountLimit')} {convertLimitAmount?.toLocaleString()}
                        </Label>
                      )}
                      {isSaveButtonClicked === true && filterCurrency?.buyFrom?.active === false && (
                        <Label className="p-0" style={{ fontSize: 14, color: 'red', fontWeight: 400 }}>
                          {errMsgCurrency}
                        </Label>
                      )}
                    </div>
                  </div>
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.itemName')}</Label>
                      <Label style={{ color: 'red' }}>*</Label>
                    </div>
                    <div className="col-sm-9 box-space">
                      <ValidatedField
                        id="item_name_option"
                        name="item_name"
                        data-cy="item_name"
                        type="select"
                        onChange={handlerItem}
                        value={itemName}
                        required
                        validate={
                          isNew && {
                            required: { value: true, message: translate('entity.validation.required') },
                          }
                        }
                      >
                        <option key="0" />
                        {commodities?.map(item => (
                          <option value={item.name} key={item.id} >
                            {item.name}
                          </option>
                        ))}
                      </ValidatedField>
                    </div>
                  </div>
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.description')}</Label>
                    </div>
                    <div className="col-sm-9 box-space">
                      <ValidatedField
                        id="description"
                        name="description"
                        data-cy="description"
                        type="textarea"
                        value={Storage.session.get('locale') === 'en' ? itemDescription : itemDescriptionInArabic}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.price')}</Label>
                    </div>
                    <div
                      className="row col-sm-9"
                      style={
                        Storage.session.get('locale') === 'en'
                          ? { paddingRight: '0px', paddingLeft: '24px' }
                          : { paddingRight: '24px', paddingLeft: '0px' }
                      }
                    >
                      <div className="col-5 col-sm-3 px-0 box-space">
                        <ValidatedField
                          id="currency_name_option"
                          name="currency_name"
                          data-cy="currency_name"
                          type="text"
                          value={currencyName}
                          disabled={true}
                        />
                      </div>
                      <div className="col-7 col-sm-9 px-0 box-space">
                        <ValidatedField
                          id="price"
                          name="price"
                          data-cy="price"
                          value={itemPrice.toLocaleString()}
                          disabled={true}
                          style={{
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.quantity')}</Label>
                    </div>
                    <div
                        className="row col-sm-9"
                        style={
                          Storage.session.get('locale') === 'en'
                              ? { paddingRight: '0px', paddingLeft: '24px' }
                              : { paddingRight: '24px', paddingLeft: '0px' }
                        }
                    >
                      <div className="col-5 col-sm-3 px-0 box-space">
                        <ValidatedField
                            id="quantity_unit"
                            name="quantity_unit"
                            data-cy="quantity_unit"
                            type="text"
                            value="Ton"
                            disabled={true}
                        />
                      </div>
                      <div className="col-7 col-sm-9 px-0 box-space">
                        <ValidatedField
                            id="quantity"
                            name="quantity"
                            data-cy="quantity"
                            value={itemQuantity.toLocaleString()}
                            disabled={true}
                            style={{
                              textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                            }}
                        />
                      </div>
                    </div>
                  </div>
                  <p hidden={validationForCommodity === true} className="mt-1 mb-0" style={{ color: "red", fontWeight: 400 }}>{translate("comexApp.quotation.validationForCommodity")}</p>
                  <div className="form-group row" style={{ alignItems: 'center' }} hidden={isNew?bankEntity?.displayTransactionFee === false : quotationEntity?.bank?.displayTransactionFee === false}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.transactionFee')}</Label>
                    </div>
                    <div
                      className="row col-sm-9"
                      style={
                        Storage.session.get('locale') === 'en'
                          ? { paddingRight: '0px', paddingLeft: '24px' }
                          : { paddingRight: '24px', paddingLeft: '0px' }
                      }
                    >
                      <div className="col-5 col-sm-3 px-0 box-space">
                        <ValidatedField
                          id="currency_name_option"
                          name="currency_name"
                          data-cy="currency_name"
                          type="text"
                          value={displayCurrencyName || ''}
                          disabled={true}
                        />
                      </div>
                      <div className="col-7 col-sm-9 px-0 box-space">
                        <ValidatedField
                          id="fee"
                          name="fee"
                          data-cy="fee"
                          value={transactionFee.toLocaleString()}
                          disabled={true}
                          style={{
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {isDefaultCurrency &&
                    (isNew ? bankEntity?.displayFeeInTransactionCurrency : quotationEntity?.bank?.displayFeeInTransactionCurrency) && (
                    <div className="form-group row" style={{ alignItems: 'center' }} hidden={isNew ? bankEntity?.displayFeeInTransactionCurrency === false : quotationEntity?.bank?.displayFeeInTransactionCurrency === false}>
                      <div className="col-sm-3">
                        <Label>{translate('comexApp.quotation.transactionFeeInQuotationCurrency')}</Label>
                      </div>
                      <div
                        className="row col-sm-9"
                        style={
                          Storage.session.get('locale') === 'en'
                            ? { paddingRight: '0px', paddingLeft: '24px' }
                            : { paddingRight: '24px', paddingLeft: '0px' }
                        }
                      >
                        <div className="col-5 col-sm-3 px-0 box-space">
                          <ValidatedField
                            id="quotation_currency_name_option"
                            name="quotation_currency_name"
                            data-cy="quotation_currency_name"
                            type="text"
                            value={currencyName || ''}
                            disabled={true}
                          />
                        </div>
                        <div className="col-7 col-sm-9 px-0 box-space">
                          <ValidatedField
                            id="quotation_fee"
                            name="quotation_fee"
                            data-cy="quotation_fee"
                            value={transactionFeeInQuotationCurrency.toLocaleString()}
                            disabled={true}
                            style={{
                              textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="form-group row" style={{ alignItems: 'center' }} hidden>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.totalAmount')}</Label>
                    </div>
                    <div
                      className="row col-sm-9"
                      style={
                        Storage.session.get('locale') === 'en'
                          ? { paddingRight: '0px', paddingLeft: '24px' }
                          : { paddingRight: '24px', paddingLeft: '0px' }
                      }
                    >
                      <div className="col-5 col-sm-3 px-0 box-space">
                        <ValidatedField
                          id="currency_name_option"
                          name="currency_name"
                          data-cy="currency_name"
                          type="text"
                          value={currencyName || ''}
                          disabled={true}
                        />
                      </div>
                      <div className="col-7 col-sm-9 px-0 box-space">
                        <ValidatedField
                          id="totalAmount"
                          name="totalAmount"
                          data-cy="totalAmount"
                          value={itemTotalAmount.toLocaleString()}
                          disabled={true}
                          style={{
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  &nbsp;
                  <Divider />
                  &nbsp;
                  <ThemeProvider theme={responsiveFont}>
                    <Typography variant="h6" component="div">
                      <strong>{translate('comexApp.quotation.requestedBy')}</strong>
                    </Typography>
                  </ThemeProvider>
                  &nbsp;
                  <div className="form-group row" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3">
                      <Label>{translate('comexApp.quotation.nationalId')}</Label>
                      <Label style={{ color: 'red' }}>*</Label>
                    </div>
                    <div className="col-sm-9">
                      <ValidatedField
                        id="national_id"
                        name="national_id"
                        data-cy="national_id"
                        type="text"
                        size={10}
                        value={cusIc ? cusIc : ''}
                        onChange={e => {
                          setCusIc(e.target.value);
                        }}
                        onKeyPress={e => {
                          if(e.key === "Enter"){
                            e.preventDefault();
                            customerSearch();
                          }
                        }}
                        required
                        validate={{
                          required: { value: true, message: translate('entity.validation.required') },
                        }}
                      />
                    </div>
                  </div>
                  <div hidden={errorSearchNationalId} className="form-group row mb-2" style={{ alignItems: 'center' }}>
                    <div className="col-sm-3"></div>
                    <div className="col-sm-9">
                      <Label style={{ fontSize: 14, color: 'red', fontWeight: 400 }}>
                        <Translate contentKey={'global.messages.validate.searchNationalId.required'} />
                      </Label>
                    </div>
                  </div>
                  <div className="quotationBtn col-sm-12 pb-3">
                    <Button color="primary" id="search-customer" className="searchBtn" onClick={customerSearch}>
                      <Row className={'px-1'}>
                        <Col className={'px-1'}>
                          <Translate contentKey={'comexApp.quotation.searchCustomerButton'} />
                        </Col>
                        <Col className={'px-1'}>
                          <FontAwesomeIcon icon={faSearch} />
                        </Col>
                      </Row>
                    </Button>
                  </div>
                  <div hidden={hideCustomer}>
                    <div className="form-group row" style={{ alignItems: 'center' }}>
                      <div className="col-sm-3">
                        <Label>{translate('comexApp.quotation.customer')}</Label>
                        <Label style={{ color: 'red' }}>*</Label>
                      </div>
                      <div className="col-sm-9" style={{ marginBottom: '10px' }}>
                        <ValidatedField
                          id="cust_name"
                          name="cust_name"
                          data-cy="cust_name"
                          disabled={isNew && disable}
                          type="text"
                          value={cusName}
                          style={{ marginBottom: '-20px' }}
                          onChange={event => {
                            const cusNameChange = event.target.value.replace(/[^a-zA-Z0-9\s]/g, '');
                            setCusName(cusNameChange);
                          }}
                          required
                          validate={
                            cusName === ''
                              ? {
                                  required: { value: true, message: translate('entity.validation.required') },
                                }
                              : {
                                  required: false,
                                }
                          }
                        />
                        <div hidden={!limitCusName}>
                          <small style={{ fontStyle: 'italic' }}>{translate('entity.validation.customerNameValidate')}</small>
                        </div>
                        <div hidden={limitCusName}>
                          <small style={{ fontSize: 14, color: 'red', fontWeight: 400, paddingLeft: '0px', paddingRight: '8px' }}>
                            <Translate contentKey={'global.messages.validate.customerName.maxlength'} />
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row" style={{ alignItems: 'center' }}>
                      <div className="col-sm-3">
                        <Label>{translate('comexApp.quotation.nationality')}</Label>
                        <Label style={{ color: 'red' }}>*</Label>
                      </div>
                      <div className="col-sm-9">
                        <ValidatedField
                          id="cust_nationality_option"
                          name="cust_nationality"
                          data-cy="cust_nationality"
                          type="select"
                          disabled={isNew && disable}
                          onChange={handlerNationality}
                          value={
                            Storage.session.get('locale') === 'en'
                              ? cusNationality
                              : countryList.find(c => c.Desc_en === cusNationality)['Desc_ar']
                          }
                          style={{
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          }}
                          required
                          validate={
                            isNew && {
                              required: { value: true, message: translate('entity.validation.required') },
                            }
                          }
                        >
                          {Storage.session.get('locale') === 'en'
                            ? countryList
                              ? Object.keys(countryList).map((key, index) => (
                                  <option key={index} value={countryList[key]['Desc_en']}>
                                    {countryList[index]['Desc_en']}
                                  </option>
                                ))
                              : null
                            : countryList
                            ? Object.keys(countryList).map((key, index) => (
                                <option key={index} value={countryList[key]['Desc_ar']}>
                                  {countryList[index]['Desc_ar']}
                                </option>
                              ))
                            : null}
                        </ValidatedField>
                      </div>
                    </div>

                    <div className="form-group row mb-2" style={{ alignItems: 'center' }}>
                      <div className="col-sm-3">
                        <Label>{translate('comexApp.quotation.phoneNo')}</Label>
                        <Label style={{ color: 'red' }}>*</Label>
                      </div>
                      <div className="col-sm-9" style={{marginBottom: '-10px'}}>
                        <PhoneInput
                          country={countryCode}
                          value={phone}
                          onChange={setPhone}
                          inputStyle={{
                            marginBottom: '2%',
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                            direction: 'ltr',
                          }}
                          disabled={isNew && disable}
                          disableDropdown={true}
                        />
                      </div>

                      {errorPhoneExist !== '' && (
                        <div className="form-group row mb-2" style={{ alignItems: 'center' }}>
                          <div className="col-sm-3"></div>
                          <div className="col-sm-9" style={{ marginBottom: '-10px' }}>
                            <Label style={{ color: "#DC3545", fontSize: 14, fontWeight: 400, paddingLeft: 0, marginTop: '5px' }}>
                              {errorPhoneExist}
                            </Label>
                          </div>
                        </div>
                      )}

                      <div hidden={errorPhone} className="form-group row mb-2" style={{ alignItems: 'center' }}>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                          <small style={{ fontSize: 14, color: 'red', fontWeight: 400, paddingLeft: '5px', paddingRight: '8px' }}>
                            <Translate contentKey={'global.messages.validate.phone.required'} />
                          </small>
                        </div>
                      </div>
                    </div>

                    <div className="form-group row" style={{ alignItems: 'center' }}>
                      <div className="col-sm-3">
                        <Label>{translate('comexApp.quotation.email')}</Label>
                      </div>
                      <div className="col-sm-9">
                        <ValidatedField
                          id="email"
                          name="email"
                          type="email"
                          placeholder={translate('comexApp.quotation.optionalField')}
                          disabled={isNew && disable}
                          style={{
                            textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
                          }}
                          onChange={event => checkExistingEmail(event.target.value)}
                          value={cusEmail}
                          // required
                          validate={

                              {
                                  // required: {
                                  //   value: true,
                                  //   message: translate('global.messages.validate.email.required'),
                                  // },
                                  minLength: {
                                    value: 5,
                                    message: translate('global.messages.validate.email.minlength'),
                                  },
                                  maxLength: {
                                    value: 254,
                                    message: translate('global.messages.validate.email.maxlength'),
                                  },
                                  pattern: {
                                    value:
                                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: translate('global.messages.validate.email.invalid'),
                                  },

                                }
                          }
                        />
                      </div>
                      <div hidden={requiredEmail} className="form-group row mb-2" style={{ alignItems: 'center' }}>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                          <small style={{ fontSize: 14, color: 'red', fontWeight: 400, paddingLeft: '5px', paddingRight: '8px' }}>
                            <Translate contentKey={'global.messages.validate.email.required'} />
                          </small>
                        </div>
                      </div>
                      <div hidden={errorEmail} className="form-group row mb-2" style={{ alignItems: 'center' }}>
                        <div className="col-sm-3"></div>
                        <div className="col-sm-9">
                          <small style={{ fontSize: 14, color: 'red', fontWeight: 400, paddingLeft: '5px', paddingRight: '8px' }}>
                            <Translate contentKey={'register.messages.error.emailexists'} />
                          </small>
                        </div>
                      </div>
                    </div>
                    {operationTime === false ? (
                      <div className="col-sm-12" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <span style={{ color: 'red' }}>
                          <Translate
                            contentKey="home.operationTime"
                            interpolate={{ startTime: moment(localStart).format('hh:mm A'), endTime: moment(localEnd).format('hh:mm A') }}
                          ></Translate>
                        </span>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="quotationBtn col-sm-12">
                      <Button
                        color="primary"
                        id="save-entity"
                        data-cy="entityCreateSaveButton"
                        type="submit"
                        disabled={updating || isSaveButtonClicked2 || validationForCommodity === false}
                        onClick={() => {
                          validateCurrency();
                        }}
                      >
                        <Translate contentKey={isNew ? 'comexApp.quotation.createBtnLabel' : 'comexApp.quotation.updateBtnLabel'}>
                          Create
                        </Translate>
                      </Button>
                    </div>
                  </div>
                </ValidatedForm>
              </>
            )}
          </Col>
        </Row>

        <Col md="8" lg="6" style={{ marginTop: '28px', marginBottom: '32px' }}>
          <Button onClick={handleBack}>
            <FontAwesomeIcon icon="arrow-left" />
            &nbsp;
            <Translate contentKey="entity.action.back">Back</Translate>
          </Button>
        </Col>
      </Row>
    </>
  );
};
//

export default QuotationUpdate;
