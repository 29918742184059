import React, { useState, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity, updateEntity, createEntity, reset } from './commodity.reducer';
import { createEntity as createCommodityLog } from '../commodity-log/commodity-log.reducer';
import { ICommodity } from 'app/shared/model/commodity.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const CommodityUpdate = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const [isNew] = useState(!props.match.params || !props.match.params.id);

  const commodityEntity = useAppSelector(state => state.commodity.entity);
  const loading = useAppSelector(state => state.commodity.loading);
  const updating = useAppSelector(state => state.commodity.updating);
  const updateSuccess = useAppSelector(state => state.commodity.updateSuccess);
  const handleClose = () => {
    props.history.push('/commodity' + props.location.search);
  };

  const [formValues, setFormValues] = useState({
    id: '',
    name: '',
    description: '',
    price: '',
    stock: '',
    currency: '',
    ownership: 'Bursa', // Default ownership
  });

  useEffect(() => {
    if (!isNew && commodityEntity) {
      setFormValues({
        id: commodityEntity.id ? commodityEntity.id.toString() : '',
        name: commodityEntity.name || '',
        description: commodityEntity.description || '',
        price: commodityEntity.price ? commodityEntity.price.toString() : '',
        stock: commodityEntity.stock ? commodityEntity.stock.toString() : '',
        currency: commodityEntity.currency || '',
        ownership: commodityEntity.ownership || 'Bursa',
      });
    }
  }, [commodityEntity, isNew]);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(props.match.params.id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...commodityEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
      const logEntity = {
        commodity: entity,
        commodityPrice: entity.price,
      };
      dispatch(createCommodityLog(logEntity));
    } else {
      dispatch(updateEntity(entity));
      if(commodityEntity.price !== entity.price) {
        const logEntity = {
          commodity: entity,
          commodityPrice: entity.price,
        };
        dispatch(createCommodityLog(logEntity));
      }
    }
  };

  // const defaultValues = () =>
  //   isNew
  //     ? {}
  //     : {
  //         ...commodityEntity,
  //       };
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value,
    }));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="comexApp.commodity.home.createOrEditLabel" data-cy="CommodityCreateUpdateHeading">
            <Translate contentKey="comexApp.commodity.home.createOrEditLabel">Create or edit a Commodity</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm onSubmit={() => saveEntity(formValues)}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="commodity-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                  value={formValues.id}
                />
              ) : null}
              <ValidatedField
                label={translate('comexApp.commodity.name')}
                id="commodity-name"
                name="name"
                data-cy="name"
                type="text"
                value={formValues.name}
                onChange={handleInputChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('comexApp.commodity.description')}
                id="commodity-description"
                name="description"
                data-cy="description"
                type="text"
                value={formValues.description}
                onChange={handleInputChange}
              />
              <ValidatedField
                label={translate('comexApp.commodity.price')}
                id="commodity-price"
                name="price"
                data-cy="price"
                value={formValues.price}
                onChange={handleInputChange}
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('comexApp.commodity.stock')}
                id="commodity-stock"
                name="stock"
                data-cy="stock"
                type="text"
                value={formValues.stock}
                onChange={handleInputChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  min: { value: 0, message: translate('entity.validation.min', { min: 0 }) },
                  validate: v => isNumber(v) || translate('entity.validation.number'),
                }}
              />
              <ValidatedField
                label={translate('comexApp.commodity.currency')}
                id="commodity-currency"
                name="currency"
                data-cy="currency"
                type="text"
                value={formValues.currency}
                onChange={handleInputChange}
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                id="commodity-ownership"
                name="ownership"
                data-cy="ownership"
                label={translate('comexApp.commodity.ownership')}
                type="select"
                value={formValues.ownership}
                onChange={handleInputChange}
                validate={{
                  required: { value: true, message:translate('entity.validation.required')},
                }}
              >
                <option value={'Bursa'} key={'Bursa'} defaultValue={'Bursa'}>{translate('comexApp.commodity.ownershipBursa')}</option>
                <option value={'Local'} key={'Local'}>{translate('comexApp.commodity.ownershipLocal')}</option>
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/commodity" replace>
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default CommodityUpdate;
