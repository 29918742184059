import React, { useEffect, useState } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col, Input, Label, Form } from 'reactstrap';
import Draggable from 'react-draggable';
import { isNumber, Storage, translate, Translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getEntitiesWithoutPending as getBanks, getEntityWithoutPending, updateEntity as updateBank } from './bank.reducer';
import { createEntity as createBankApiSetting, getEntityByBankId } from '../bank-api-setting/bank-api-setting.reducer';
import {
  createEntity,
  getBranchListByBankId,
  partialUpdateEntity,
  updateEntity,
  getEntity as getBranch
} from 'app/entities/branch/branch.reducer';
import {
  getAccountsByBranchId,
  getUsers,
  getUsersAsAdmin,
  getUserByIdAsAdmin, getUserByIdAsOtherRoles, getUsersAsAlmoskhAdminAndStaff
} from 'app/modules/administration/user-management/user-management.reducer';
import { AUTHORITIES } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './bank-detail.scss';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { createTheme, responsiveFontSizes, styled, ThemeProvider, useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper, { PaperProps } from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import EditIcon from '@mui/icons-material/Edit';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import EmailIcon from '@mui/icons-material/Email';
import ExpandLessRoundedIcon from '@mui/icons-material/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  createEntity as createComexBankFee,
  getFeeListByBankId,
  updateEntity as updateComexBankFee,
  reset, deleteEntityFromBank
} from '../comex-bank-fee/comex-bank-fee.reducer';
import { createEntity as createComexBankFeeLog } from '../comex-bank-fee-log/comex-bank-fee-log.reducer';
import { getAllEntities as getCurrencyIndexEntities } from 'app/entities/currency-index/currency-index.reducer';
import DialogTitle from '@mui/material/DialogTitle';
import { useForm } from 'react-hook-form';

import {createEntity as createCommissionLog} from "app/entities/commission-log/commission-log.reducer";
import BankDetailFeeDeleteDialog from "app/entities/bank/bank-detail-fee-delete-dialog";
import { IBranch } from "app/shared/model/branch.model";
import CloseIcon from "@mui/icons-material/Close";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import { selectIdValue } from "@reduxjs/toolkit/dist/entities/utils";
import { Checkbox, DialogContentText } from '@mui/material';
import { formatDate } from 'app/shared/util/date-utils';
import S3 from 'react-aws-s3';
import AWS from 'aws-sdk';
import axios from 'axios';
import {getCommoditiesByBankId} from "app/entities/commodity/commodity.reducer";
import {getAllEntities as getCurrencyRates} from "app/entities/currency-rates/currency-rates.reducer";

export const BankDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const bankEntity = useAppSelector(state => state.bank.entity);
  const bankApiSettingEntity = useAppSelector(state => state.bankApiSetting.entity);
  const commissionLog = useAppSelector(state => state.commissionLog.entity);
  const comexBankFeeList = useAppSelector(state => state.comexBankFee.entities);
  const updateSuccessBankFee = useAppSelector(state => state.comexBankFee.updateSuccess);
  const branchList = useAppSelector(state => state.branch.entities);
  const accountsByBranchId = useAppSelector(state => state.userManagement.accountsByBranchId);
  const loading = useAppSelector(state => state.comexBankFee.loading);
  const updating = useAppSelector(state => state.comexBankFee.updating);

  const updatingBank = useAppSelector(state => state.bank.updating);
  const updateSuccessBranch = useAppSelector(state => state.branch.updateSuccess);
  const areaManager = useAppSelector(state => state.userManagement.areaManager);
  const accountsUser = useAppSelector(state => state.userManagement.accountsUser);

  const [open, setOpen] = useState(false);
  const [openBranchEdit, setOpenBranchEdit] = useState(false);
  const [openBranchCreate, setOpenBranchCreate] = useState(false);
  const [branchName, setBranchName] = useState('');
  const [branchNameInput, setBranchNameInput] = useState('');
  const [fromValue, setFromValue] = useState(1);
  const [toValue, setToValue] = useState("");
  const [oldToValue, setOldToValue] = useState("");
  const [eachAmountValue, setEachAmountValue] = useState("");
  const [oldEachAmountValue, setOldEachAmountValue] = useState("");
  const [checkedToValue, setCheckedToValue] = useState(1);
  const [checkedEachAmountValue, setCheckedEachAmountValue] = useState(1);
  const [toValueDisplayed, setToValueDisplayed] = useState("");
  const [toLimitValue, setToLimitValue] = useState(99999999999999);
  const [amount, setAmount] = useState("");
  const [checkedAmount, setCheckedAmount] = useState(1);
  const [amountDisplayed, setAmountDisplayed] = useState("");
  const [oldAmount, setOldAmount] = useState("");
  const [loadingSpinner, setLoadingSpinner] = useState(true);

  const [openDelete, setOpenDelete] = useState<number | null>(null);
  const [isNew, setIsNew] = useState(true);
  const [comexBankFeeForm, setComexBankFeeForm] = useState([] as any);
  const fullWidth = true;
  const approvalTime = bankEntity?.approvalPeriod;
  const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('sm');
  const [validateFromTo, setValidateFromTo] = useState('');
  const [noDecimalAllowed, setNoDecimalAllowed] = useState(true);
  const [feeCollapse, setFeeCollapse] = React.useState(false);
  const [reportCollapse, setReportCollapse] = React.useState(false);
  const [apiSettingsCollapse, setApiSettingsCollapse] = React.useState(false);
  const [valueSellConfirmationEndpoint, setValueSellConfirmationEndpoint] = React.useState('');
  const [valueVendorName, setValueVendorName] = React.useState('');
  const [valueVendorCif, setValueVendorCif] = React.useState('');
  const [isBranchEmpty, setIsBranchEmpty] = useState(false);
  const [isBranchExists, setIsBranchExists] = useState(false);
  const [commissionData,setCommissionData]=useState(false);
  const [commissionAmt,setCommissionAmt]=useState("0");

  const [checkedCommissionAmt, setCheckedCommissionAmt] = useState(0);
  const [commissionForEachAmt,setCommissionnForEachAmt]=useState("0");

  const [checkedCommissionForEachAmt, setCheckedCommissionForEachAmt] = useState(0);
  const [updateCommission,setUpdateCommission]=useState(true);

  const [isCommissionAmountFieldClicked, setIsCommissionAmountFieldClicked] = useState(false);
  const [isCommissionForEachFieldClicked, setIsCommissionForEachFieldClicked] = useState(false);

  const [openCommission, setOpenCommission] = useState(false);

  const [availableCurrency, setAvailableCurrency] = useState([]);
  const [formStateFee, setFormStateFee] = useState([]);
  const [formStateCommission, setFormStateCommission] = useState([]);

  const [oldFormStateFee, setOldFormStateFee] = useState([]);
  const [oldFormStateCommission, setOldFormStateCommission] = useState([]);

  const [selectedCurrency, setSelectedCurrency] = useState(0);
  const [filteredComexBankFeeList, setFilteredComexBankFeeList] = useState([]);
  const [filteredComexBankCommissionList, setFilteredComexBankCommissionList] = useState([]);
  const [uniqueFromAmountFee, setUniqueFromAmountFee] = useState([]);

  const [selectedFeeType, setSelectedFeeType] = useState('Lump Sum');
  const [oldSelectedFeeType, setOldSelectedFeeType] = useState("");
  const [bankFeeId, setBankFeeId] = useState("");
  const [groupedData, setGroupedData] = useState([]);

  const [updatedFeeArray, setUpdatedFeeArray] = useState([]);
  const [updatedCommissionArray, setUpdatedCommissionArray] = useState([]);

  const newComexBankFeeList = comexBankFeeList?.filter(c => c?.feeType !== "commission");
  const [isPending, setIsPending] = useState(false);

  const [s3BucketName, setS3BucketName] = useState<string | null>(null);
  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get('/api/config/bucketName');
        const bucketName = response.data.bucketName;
        setS3BucketName(bucketName);
      } catch (error) {
        console.error('Error fetching bucket name:', error);
      }
    };
    fetchConfig();
  }, []);

  useEffect(() => {
      dispatch(getEntityWithoutPending(props.match.params.id));
      dispatch(getBanks({}));
      if (account.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(getUsersAsAdmin({}));
      else
        // dispatch(getUsers({}));
        dispatch(getUsersAsAlmoskhAdminAndStaff({}));
  }, []);

  useEffect(() => {
    dispatch(getCurrencyIndexEntities({})).then(res => {
      const sortedCurrencies = [...(res?.payload['data'] || [])].sort((a, b) => a.id - b.id);
      setAvailableCurrency(sortedCurrencies);
      const initFormState = [];
      sortedCurrencies?.forEach((item, index) => {
        initFormState.push({ amount: '', currencyId: item?.id });
      });
      setFormStateFee(initFormState);
      setFormStateCommission(initFormState);
    });
  }, []);

  let selectedBranchEntity: IBranch = null;
  const {
    handleSubmit,
    register,
    reset: resetForm,
    formState: { errors },
  } = useForm({ mode: 'onTouched', defaultValues: comexBankFeeForm });

  useEffect(() => {
    dispatch(getFeeListByBankId(props.match.params.id));
  }, [updateSuccessBankFee]);

  useEffect(() => {
    dispatch(getBranchListByBankId(props.match.params.id));
  }, [updateSuccessBranch]);

  useEffect(() => {
    resetForm(comexBankFeeForm);
  }, [reset, comexBankFeeForm]);

  function checkArrayDifferences(array1, array2) {
    const dict1 = {};
    const dict2 = {};

    array1?.forEach(item => {
      dict1[item?.id] = item?.amount;
    });

    array2?.forEach(item => {
      dict2[item?.id] = item?.amount;
    });

    const differences = [];
    for (const id in dict1) {
      if (Object.prototype.hasOwnProperty.call(dict2, id) && dict1[id] !== dict2[id]) {
        differences.push({ id, oldAmount: dict1[id], newAmount: dict2[id] });
      }
    }

    return differences;
  }

  const handleClickOpen = async (feeId, fromToValue) => {
    await  dispatch(getFeeListByBankId(props.match.params.id));
    const bankFeeById = comexBankFeeList?.find(fee => fee?.id === feeId && fee?.feeType !== "commission");
    const itemIndex = availableCurrency?.findIndex(item => item?.id === feeId);

    if (bankFeeById?.feeType === "Lump Sum") {
      setSelectedFeeType("Lump Sum");
    } else if (bankFeeById?.feeType === "Percentage") {
      setSelectedFeeType("Percentage");
    }

    if (feeId !== 0) {
      setToValue(bankFeeById?.fromTo?.toLocaleString());
      setAmount(bankFeeById?.amount?.toLocaleString());
      setCheckedToValue(bankFeeById?.fromTo);
      setCheckedAmount(bankFeeById?.amount);
      setBankFeeId(bankFeeById?.id?.toString());
      setOldAmount(bankFeeById?.amount?.toLocaleString());
      setOldToValue(bankFeeById?.fromTo?.toLocaleString());
      setSelectedFeeType(bankFeeById?.feeType);
      setOldSelectedFeeType(bankFeeById?.feeType);

      localStorage.setItem("feeAmount", bankFeeById?.fromTo?.toLocaleString());

      if (bankFeeById?.currencyId !== null) {
        const updatedFormStateFee = comexBankFeeList
          .filter(item => item.feeType === "Lump Sum" && item.fromTo === fromToValue)
          .sort((a, b) => a.currencyId - b.currencyId)
          .map((item, index) => ({...item, amount: item?.amount}));

        setFormStateFee(updatedFormStateFee);

        for (let i = 0; i < groupedData.length; i++) {
          for (let j = 0; j < groupedData[i].fee.length; j++) {
            if (groupedData[i].fee[j].id === feeId) {
              setOldFormStateFee(groupedData[i].fee);
            }
          }
        }
      }

    }
    setComexBankFeeForm({});
    if (feeId === 0) {
      setIsNew(true);
      if (newComexBankFeeList?.length !== 0) {
        const maxFromTo = Math.max(...newComexBankFeeList.map(item => Number(item.fromTo))) + 1;
        setFromValue(maxFromTo);
        setToLimitValue(bankEntity?.transactionAmountLimit);
        setValidateFromTo(`${maxFromTo} - ${bankEntity?.transactionAmountLimit}`);
      } else {
        setFromValue(1);
        setToLimitValue(bankEntity?.transactionAmountLimit);
        setValidateFromTo("1 - " + bankEntity?.transactionAmountLimit);
      }
    }
    if (feeId > 0) {
      setIsNew(false);
      if (comexBankFeeList.length > 1) {
        filterUniqueFromAmountFeeUpdate(fromToValue);

        for (let i = 0; i < groupedData.length; i++) {
          for (let j = 0; j < groupedData[i].fee.length; j++) { // Iterate over each fee within the group
            if (groupedData[i].fee[j].id === feeId) {

              setComexBankFeeForm({
                ...groupedData[i].fee[j],
                fromTo: groupedData[i].fee[j].fromTo,
                amount: groupedData[i].fee[j].amount
              });
              if (i === 0) {
                setValidateFromTo("1 - " + (Number(groupedData[i]?.fromTo) + 1));
                setFromValue(1);
                if (groupedData?.length > 1) {
                  setToLimitValue(groupedData[i + 1]?.fromTo);
                }
              }
              if (i !== 0 && i !== groupedData.length - 1) {
                const fromToPrev = groupedData[i - 1]?.fromTo || 0;
                const fromToNext = groupedData[i + 1]?.fromTo || 0;
                setValidateFromTo((Number(fromToPrev) + 1) + " - " + fromToNext);
                setFromValue(fromToPrev + 1);
                setToLimitValue(fromToNext);
              }
              if (i === groupedData.length - 1) {
                const fromToPrev = groupedData[i - 1]?.fromTo || 0;
                setValidateFromTo((Number(fromToPrev) + 1) +" - "+ bankEntity?.transactionAmountLimit);
                setFromValue(fromToPrev + 1);
                setToLimitValue(bankEntity?.transactionAmountLimit);
              }
            }
          }
        }
      }
      if (newComexBankFeeList?.length === 1) {
        setComexBankFeeForm({
          ...newComexBankFeeList[0],
          fromTo: newComexBankFeeList[0]?.fromTo?.toLocaleString(),
          amount: newComexBankFeeList[0]?.amount?.toLocaleString()
        });
        setValidateFromTo("1 - 99999999999999");
        setFromValue(1);
        setToLimitValue(9999999999999);
      }
    }
    if(!isPending) {
      setOpen(true);
    }
  };

  const filterUniqueFromAmountFeeUpdate = (fromToValue) => {
    const onlyFeeList = comexBankFeeList?.filter(c => c?.feeType !== "commission");
    const unique = onlyFeeList?.reduce((uniqueItems, item) => {
      if (item?.fromTo < fromToValue && !uniqueItems?.some(i => i?.fromTo === item?.fromTo)) {
        uniqueItems?.push(item);
      }
      return uniqueItems;
    }, []);

    setUniqueFromAmountFee(unique);
  };


  const handleClickOpenCommission = feeId => {
    const bankCommissionById = comexBankFeeList?.find(fee => fee?.id === feeId && fee?.feeType === "commission");
    const commissionList = comexBankFeeList?.filter(fee => fee?.id === feeId && fee?.feeType === "commission");
    const itemIndex = availableCurrency.findIndex(item => item.id === feeId);
    if (feeId !== 0) {
      setEachAmountValue(bankCommissionById.fromTo.toLocaleString());
      setOldEachAmountValue(bankCommissionById.fromTo.toLocaleString());
      setCheckedEachAmountValue(bankCommissionById.fromTo);

      const updatedFormStateCommission = comexBankFeeList
        .filter(item => item.feeType === "commission")
        .sort((a, b) => a.currencyId - b.currencyId)
        .map((item, index) => ({ ...item, amount: item?.amount }));

      setFormStateCommission(updatedFormStateCommission);
      setOldFormStateCommission(commissionList);

    }
    setOpenCommission(true);
  };

  const handleClose = () => {
    setComexBankFeeForm({});
    setFromValue(1);
    if (newComexBankFeeList?.length !== 0) {
      const maxFromTo = Math.max(...newComexBankFeeList.map(item => Number(item.fromTo))) + 1;
      setFromValue(maxFromTo);
    }
    setToLimitValue(bankEntity?.transactionAmountLimit);
    setOpen(false);
    setOpenCommission(false);
    setCheckedAmount(1);
    setCheckedToValue(1);
    setToValue("");
    setOldToValue("");
    setOldSelectedFeeType("");
    setOldEachAmountValue("");
    setCheckedEachAmountValue(1);
    setAmount("");
    setOldAmount("");
    setBankFeeId("");
    setSelectedFeeType("Lump Sum");

    setOldFormStateFee([]);
    setOldFormStateCommission([]);
    filterUniqueFromAmountFee();
    const updatedFormStateFee = formStateFee.map(item => ({
      ...item,
      amount: ''
    }))
      .sort((a, b) => a.currencyId - b.currencyId);

    setFormStateFee(updatedFormStateFee);

    const updatedFormStateCommission = formStateCommission.map(item => ({
      ...item,
      amount: ''
    }));
    setFormStateCommission(updatedFormStateCommission);

  };

  const handleEditBank = () => {
    props.history.push(`/bank/${bankEntity?.id}/edit`);
  }

  const handleEditBranchClose = () => {
    setBranchName(branchValue);
    setOpenBranchEdit(false);
  };

  const handleCreateBranchClose = () => {
    setOpenBranchCreate(false);
    setBranchNameInput('');
    setIsBranchEmpty(false);
    setIsBranchExists(false);
  };

  const handleCommission=(e)=>{

    setIsCommissionAmountFieldClicked(false);
    setIsCommissionForEachFieldClicked(false);
    const bankCommissionEntity={
      ...bankEntity,
      commission:e.target.checked,
    };

    const commissionLogEntity={
      ...commissionLog,
      id:null,
      commission:e.target.checked,
      commissionAmount:checkedCommissionAmt,
      commissionForEachAmount:checkedCommissionForEachAmt,
      bankId:bankEntity?.id,
    }


    dispatch(updateBank(bankCommissionEntity));
    dispatch(createCommissionLog(commissionLogEntity));
    setCommissionData(e.target.checked);
    if(e.target.checked===true){
      setUpdateCommission(false);
    }
    else{
      setUpdateCommission(true);
    }
  }

  const handleCommissionAmount=(e)=>{
    setIsCommissionAmountFieldClicked(true);
    setIsCommissionForEachFieldClicked(false);
    const commissionAmountValue = toEnglishDigits(e.target.value);
    // console.warn("commissionAmountValue"+commissionAmountValue);
    const commissionAmountDecimalPoints = commissionAmountValue.toString().split(".");
    // console.warn("commissionAmountDecimalPoints"+commissionAmountDecimalPoints);
    if (commissionAmountValue.toString().charAt(commissionAmountValue.length - 1) !== '.' && isNumber(commissionAmountValue.toString().charAt(commissionAmountValue.length - 1)) && commissionAmountValue.length < 20) {
      const enteredCommissionAmount = commissionAmountValue.replace(/,/g, '');
      // console.warn("enteredCommissionAmount"+enteredCommissionAmount);
      setCheckedCommissionAmt(enteredCommissionAmount);
      const cleanedCommissionAmount = enteredCommissionAmount.replace(/[^0-9.]+/g, "");
      // console.warn("cleanedCommissionAmount"+cleanedCommissionAmount);
      setCommissionAmt(cleanedCommissionAmount.toLocaleString());
    } else if (commissionAmountValue.toString().charAt(commissionAmountValue.length - 1) === '.' && commissionAmountDecimalPoints.length === 2 && commissionAmountValue.length < 20) {
      setCommissionAmt(commissionAmountValue.toLocaleString());
    } else {
      setCommissionAmt(commissionAmountValue.slice(0, -1).toLocaleString());
    }

  }

  const handleCommissionForEachAmount=(e)=>{
    setIsCommissionAmountFieldClicked(false);
    setIsCommissionForEachFieldClicked(true);
    setCommissionnForEachAmt(e.target.value);
    const commissionForEachAmtValue = toEnglishDigits(e.target.value);
    // console.warn("commissionForEachAmtValue"+commissionForEachAmtValue);
    const commissionForEachAmtDecimalPoints = commissionForEachAmtValue.toString().split(".");
    // console.warn("commissionForEachAmtDecimalPoints"+commissionForEachAmtDecimalPoints);
    if (commissionForEachAmtValue.toString().charAt(commissionForEachAmtValue.length - 1) !== '.' && isNumber(commissionForEachAmtValue.toString().charAt(commissionForEachAmtValue.length - 1)) && commissionForEachAmtValue.length < 20) {
      const enteredCommissionForEachAmount = commissionForEachAmtValue.replace(/,/g, '');
      // console.warn("enteredCommissionAmount"+enteredCommissionForEachAmount);
      setCheckedCommissionForEachAmt(enteredCommissionForEachAmount);
      const cleanedCommissionForEachAmount = enteredCommissionForEachAmount.replace(/[^0-9.]+/g, "");
      // console.warn("cleanedCommissionAmount"+cleanedCommissionForEachAmount);
      setCommissionnForEachAmt(cleanedCommissionForEachAmount.toLocaleString());
    } else if (commissionForEachAmtValue.toString().charAt(commissionForEachAmtValue.length - 1) === '.' && commissionForEachAmtDecimalPoints.length === 2 && commissionForEachAmtValue.length < 20) {
      setCommissionnForEachAmt(commissionForEachAmtValue.toLocaleString());
    } else {
      setCommissionnForEachAmt(commissionForEachAmtValue.slice(0, -1).toLocaleString());
    }
  }
  const updateCommissionBank=()=>{
    setIsCommissionAmountFieldClicked(false);
    setIsCommissionForEachFieldClicked(false);
    const bankCommissionEntity={
      ...bankEntity,
      commission:commissionData,
      commissionAmount:checkedCommissionAmt,
      commissionForEachAmount:checkedCommissionForEachAmt,
    };

    const commissionLogEntity={
      ...commissionLog,
      commission:commissionData,
      commissionAmount:checkedCommissionAmt,
      commissionForEachAmount:checkedCommissionForEachAmt,
      bankId:bankEntity?.id,
    }

    if(bankEntity?.commissionAmount!==checkedCommissionAmt || bankEntity?.commissionForEachAmount!==checkedCommissionForEachAmt || bankEntity?.commission!==commissionData){

      dispatch(updateBank(bankCommissionEntity));
      dispatch(createCommissionLog(commissionLogEntity));
    }
  }

  const updateNewCommissionBank = values => {
    setIsCommissionForEachFieldClicked(false);
    const bankCommissionEntity={
      ...bankEntity,
      commission:commissionData,
    };
    const commissionLogEntity={
      ...commissionLog,
      commission:commissionData,
      bankId:bankEntity?.id,
    }
    if(bankEntity?.commission!==commissionData){
      dispatch(updateBank(bankCommissionEntity));
      dispatch(createCommissionLog(commissionLogEntity));
    }

    // new
    if (updatedCommissionArray?.length === 0 && oldEachAmountValue === "") {
      availableCurrency.forEach((fee, index) => {
        const entity = {
          ...comexBankFeeForm,
          ...values,
          fromTo: Number(eachAmountValue),
          feeType: "commission",
          amount: formStateCommission[index]?.amount ? parseInt(formStateCommission[index]?.amount, 10) : 0,
          bank: bankEntity,
          bankId: props.match.params.id,
          currencyId: formStateCommission[index]?.currencyId,
        };
        const bankCommissionLog = {
          ...comexBankFeeForm,
          ...values,
          fromTo: Number(eachAmountValue),
          feeType: "commission",
          amount: formStateCommission[index]?.amount ? parseInt(formStateCommission[index]?.amount, 10) : 0,
          status: "CREATE",
          bank: bankEntity,
          bankId: props.match.params.id,
          currencyId: formStateCommission[index]?.currencyId,
        };
        dispatch(createComexBankFee(entity));
        dispatch(createComexBankFeeLog(bankCommissionLog));
      });
    }

    // update commission each amount (fromTo)
    if (oldEachAmountValue!== "" && oldEachAmountValue !== eachAmountValue.toLocaleString()) {
      formStateCommission.forEach((item, index) => {
        const entity = {
          ...item,
          ...values,
          id: item?.id,
          fromTo: checkedEachAmountValue,
          feeType: item?.feeType,
          amount: item?.amount?.toString(),
          bank: bankEntity,
          bankId: props.match.params.id,
          currencyId: item?.currencyId
        };
        const bankCommissionLog = {
          ...comexBankFeeForm,
          ...values,
          id: null,
          fromTo: checkedEachAmountValue,
          feeType: item?.feeType,
          amount: item?.amount?.toString(),
          status: "UPDATE",
          bank: bankEntity,
          bankId: props.match.params.id,
          currencyId: item?.currencyId,
        };
        dispatch(updateComexBankFee(entity));
        dispatch(createComexBankFeeLog(bankCommissionLog));
      });
    }

    // update commission amount (amount)
    updatedCommissionArray?.forEach((value, index) => {
      const foundItem = comexBankFeeList?.find(item => item?.id?.toString() === value?.id);
      if (foundItem) {
        const entity = {
          ...foundItem,
          ...values,
          id: foundItem?.id,
          fromTo: checkedEachAmountValue,
          feeType: foundItem?.feeType,
          amount: parseFloat(value?.newAmount).toFixed(2),
          bank: bankEntity,
          bankId: props.match.params.id,
          currencyId: foundItem?.currencyId
        };
        const bankCommissionLog = {
          ...comexBankFeeForm,
          ...values,
          id: null,
          fromTo: foundItem?.fromTo,
          feeType: foundItem?.feeType,
          amount: parseFloat(value?.newAmount).toFixed(2),
          status: "UPDATE",
          bank: bankEntity,
          bankId: props.match.params.id,
          currencyId: foundItem?.currencyId,
        };
        dispatch(updateComexBankFee(entity));
        dispatch(createComexBankFeeLog(bankCommissionLog));
      }
    });

    handleClose();
  };

  const handleFromToValidate = (e) => {
    setFromValue(Number(e.target.value));
    if (Number(e.target.value) === 1) {
      if (newComexBankFeeList?.length > 1) {
        setToLimitValue(newComexBankFeeList[0]?.fromTo);
        setValidateFromTo((Number(e.target.value) + 1) + " - " + newComexBankFeeList[0]?.fromTo);
      }
    }
    if (Number(e.target.value) > 1) {
      if (newComexBankFeeList?.length === 1) {

        setToLimitValue(9999999999999);
        setValidateFromTo((Number(e.target.value) + 1) + " - 9999999999999");
      } else {
        for (let i = 0; i < newComexBankFeeList?.length; i++) {


          if ((newComexBankFeeList[i]?.fromTo + 1).toString() === e.target.value.toString()) {
            if (i === 0) {
              setToLimitValue(newComexBankFeeList[i + 1]?.fromTo);
              setValidateFromTo((Number(e.target.value) + 1) + " - " + newComexBankFeeList[i + 1]?.fromTo);
            }
            if (i !== 0 && i !== newComexBankFeeList?.length - 1) {
              setToLimitValue(newComexBankFeeList[i + 1]?.fromTo);
              setValidateFromTo((Number(e.target.value) + 1) + " - " + newComexBankFeeList[i + 1]?.fromTo);
            }

            if (i === newComexBankFeeList?.length - 1) {

              setToLimitValue(9999999999999);
              setValidateFromTo((Number(e.target.value) + 1) + " - 9999999999999");
            }

          }
        }
      }
    }
  };
  // useEffect(() => {
  //   if(isNew && fromValue===1 && parseInt(toValue,10)===comexBankFeeList[0]?.fromTo){
  //     setValidateFromTo("1-"+(Number(comexBankFeeList[0]?.fromTo)-1));}
  // }, [isNew,fromValue,toValue]);

  useEffect(() => {
    if(bankEntity?.id!==undefined){
      setCommissionData(bankEntity?.commission);
      setUpdateCommission(!bankEntity?.commission);
      setCommissionAmt(bankEntity?.commissionAmount?.toLocaleString());
      setCheckedCommissionAmt(bankEntity?.commissionAmount);
      setCheckedCommissionForEachAmt(bankEntity?.commissionForEachAmount);
      setCommissionnForEachAmt(bankEntity?.commissionForEachAmount?.toLocaleString());

      dispatch(getEntityByBankId(bankEntity?.id))
        .then(res => {
          const data = (res.payload as { data: { sellConfirmationEndpoint?: string; vendorName?: string; vendorCif?: string } })?.data;
          setValueSellConfirmationEndpoint(data?.sellConfirmationEndpoint || "");
          setValueVendorName(data?.vendorName || "");
          setValueVendorCif(data?.vendorCif || "");
        })
        .catch(error => {
          setValueSellConfirmationEndpoint("");
          setValueVendorName("");
          setValueVendorCif("");
        });

    }

  }, [bankEntity]);

  const saveEntity =  async (values) => {
    setIsPending(true);
    const dispatchPromises = [];
    try {
      // Lump Sum
      if (selectedFeeType === 'Lump Sum') {
        // new - lump sum
        if (isNew && updatedFeeArray?.length === 0) {
          if (oldToValue === "" && oldSelectedFeeType === "") {
            availableCurrency.forEach((fee, index) => {
              const entity = {
                ...comexBankFeeForm,
                ...values,
                fromTo: checkedToValue,
                amount: formStateFee[index]?.amount ? parseInt(formStateFee[index]?.amount, 10) : 0,
                currencyId: formStateFee[index]?.currencyId,
                bank: bankEntity,
                bankId: props.match.params.id,
              };
              const bankFeeLog = {
                ...comexBankFeeForm,
                ...values,
                fromTo: checkedToValue,
                amount: formStateFee[index]?.amount ? parseInt(formStateFee[index]?.amount, 10) : 0,
                currencyId: formStateFee[index]?.currencyId,
                bank: bankEntity,
                bankId: props.match.params.id,
                status: "CREATE",
              };
              dispatchPromises.push(dispatch(createComexBankFee(entity)));
              dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLog)));
            });
          }

        } else {

          // edit fee type
          if (oldSelectedFeeType !== "" && oldSelectedFeeType !== selectedFeeType) {

            const newRows = formStateFee?.slice(1, formStateFee?.length);
            newRows?.forEach((fee, index) => {
              const entity = {
                ...comexBankFeeForm,
                ...values,
                id: null,
                feeType: selectedFeeType,
                fromTo: checkedToValue,
                amount: formStateFee[index + 1]?.amount !== "" ? parseInt(formStateFee[index + 1]?.amount, 10) : 0,
                currencyId: formStateFee[index + 1]?.currencyId,
                bank: bankEntity,
                bankId: props.match.params.id,
              };
              const bankFeeLogOtherEntities = {
                ...comexBankFeeForm,
                ...values,
                id: null,
                feeType: selectedFeeType,
                fromTo: checkedToValue,
                amount: formStateFee[index + 1]?.amount !== "" ? parseInt(formStateFee[index + 1]?.amount, 10) : 0,
                currencyId: formStateFee[index + 1]?.currencyId,
                bank: bankEntity,
                bankId: props.match.params.id,
                status: "CREATE",
              };
              dispatchPromises.push(dispatch(createComexBankFee(entity)));
              dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLogOtherEntities)));
            });

            const foundItem = comexBankFeeList?.find(item => item?.id?.toString() === bankFeeId);
            const firstEntity = {
              ...foundItem,
              ...values,
              id: foundItem?.id,
              feeType: selectedFeeType,
              fromTo: checkedToValue,
              amount: formStateFee[0]?.amount ? parseInt(formStateFee[0]?.amount, 10) : 0,
              currencyId: formStateFee[0]?.currencyId,
              bank: bankEntity,
              bankId: props.match.params.id,
            };
            const bankFeeLogfirstEntity = {
              ...comexBankFeeForm,
              ...values,
              id: null,
              feeType: selectedFeeType,
              fromTo: checkedToValue,
              amount: formStateFee[0]?.amount ? parseInt(formStateFee[0]?.amount, 10) : 0,
              currencyId: formStateFee[0]?.currencyId,
              bank: bankEntity,
              bankId: props.match.params.id,
              status: "UPDATE",
            };
            dispatchPromises.push(dispatch(updateComexBankFee(firstEntity)));
            dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLogfirstEntity)));
          }

          // edit to value - lump sum
          if (oldToValue !== "" && oldToValue !== toValue) {
            formStateFee.forEach((item, index) => {
              const entity = {
                ...item,
                ...values,
                id: item?.id,
                fromTo: checkedToValue,
                feeType: item?.feeType,
                amount: item?.amount?.toString(),
                bank: bankEntity,
                bankId: props.match.params.id,
                currencyId: item?.currencyId
              };
              const bankFeeLog = {
                ...comexBankFeeForm,
                ...values,
                id: null,
                fromTo: checkedToValue,
                feeType: item?.feeType,
                amount: item?.amount?.toString(),
                status: "UPDATE",
                bank: bankEntity,
                bankId: props.match.params.id,
                currencyId: item?.currencyId,
              };
              dispatchPromises.push(dispatch(updateComexBankFee(entity)));
              dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLog)));
            });
          }

          // edit amount - lump sum
          if (updatedFeeArray?.length > 0) {

            updatedFeeArray?.forEach((value, index) => {
              const foundItem = comexBankFeeList?.find(item => item?.id?.toString() === value?.id);
              if (foundItem) {
                const entity = {
                  ...foundItem,
                  ...values,
                  id: foundItem?.id,
                  currencyId: Number(foundItem?.currencyId),
                  feeType: foundItem?.feeType,
                  fromTo: checkedToValue,
                  amount: parseFloat(value?.newAmount).toFixed(2),
                  bank: bankEntity,
                  bankId: props.match.params.id,
                };
                const bankFeeLog = {
                  ...comexBankFeeForm,
                  ...values,
                  fromTo: checkedToValue,
                  amount: parseFloat(value?.newAmount).toFixed(2),
                  currencyId: Number(foundItem?.currencyId),
                  id: null,
                  bank: bankEntity,
                  bankId: props.match.params.id,
                  status: "UPDATE",
                };
                dispatchPromises.push(dispatch(updateComexBankFee(entity)));
                dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLog)));
              }
            });
          }

        }
      // Percentage
      } else if (selectedFeeType === 'Percentage') {
        // new - percentage
        if (isNew && oldAmount === "") {
          const entity = {
            ...comexBankFeeForm,
            ...values,
            fromTo: checkedToValue,
            amount: checkedAmount,
            bank: bankEntity,
            bankId: props.match.params.id,
          };
          const bankFeeLog = {
            ...comexBankFeeForm,
            ...values,
            fromTo: checkedToValue,
            amount: checkedAmount,
            bank: bankEntity,
            bankId: props.match.params.id,
            status: "CREATE",
          }
          dispatchPromises.push(dispatch(createComexBankFee(entity)));
          dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLog)));
          // edit amount - percentage
        } else {
          const foundItem = comexBankFeeList?.find(item => item?.id?.toString() === bankFeeId);

          // edit fee type
          if (foundItem?.feeType === "Lump Sum") {
            const entity = {
              ...foundItem,
              ...values,
              id: foundItem?.id,
              fromTo: checkedToValue,
              feeType: selectedFeeType,
              amount: checkedAmount,
              bank: bankEntity,
              bankId: foundItem?.bankId,
              currencyId: null,
            };
            const bankFeeLog = {
              ...comexBankFeeForm,
              ...values,
              fromTo: checkedToValue,
              amount: checkedAmount,
              id: null,
              bank: bankEntity,
              bankId: props.match.params.id,
              status: "UPDATE",
              currencyId: null,
            }
            dispatchPromises.push(dispatch(updateComexBankFee(entity)));
            dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLog)));

            const filteredData = comexBankFeeList?.filter(item => item?.fromTo === checkedToValue && item?.id?.toString() !== bankFeeId);
            filteredData?.map(item => {
              const comexBankFeelog={
                ...comexBankFeeForm,
                ...values,
                id:null,
                fromTo: item.fromTo,
                status:'DELETE',
                amount: item?.amount,
                bank: bankEntity,
                bankId: props.match.params.id,
                currencyId: item?.currencyId,
              }
              dispatchPromises.push(dispatch(createComexBankFeeLog(comexBankFeelog)));
              dispatchPromises.push(dispatch(deleteEntityFromBank({id: item?.id, bankId: bankEntity?.id, fromTo: checkedToValue})));
            })

          } else {
            const entity = {
              ...foundItem,
              ...values,
              id: Number(bankFeeId),
              fromTo: checkedToValue,
              amount: checkedAmount,
              bank: bankEntity,
              bankId: props.match.params.id,
              feeType: selectedFeeType,
              currencyId: null,
            };
            const bankFeeLog = {
              ...foundItem,
              ...values,
              fromTo: checkedToValue,
              amount: checkedAmount,
              id: null,
              bank: bankEntity,
              bankId: props.match.params.id,
              status: "UPDATE",
            }
            dispatchPromises.push(dispatch(updateComexBankFee(entity)));
            dispatchPromises.push(dispatch(createComexBankFeeLog(bankFeeLog)));
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      await Promise.all(dispatchPromises);
      setIsPending(false);
      handleClose();
      dispatch(getFeeListByBankId(props.match.params.id));
    }
  };

  const branchExists = branchList?.find(branch => branch?.name?.toLowerCase() === branchNameInput?.toLowerCase()) !== undefined;

  const handleBranchUpdate = () => {
    const updateBranch = { ...selectedBranchEntity, name: branchName };
    dispatch(partialUpdateEntity(updateBranch));
    setOpenBranchEdit(false);
    setBranchValue(branchName);

  };

  const handleCreateBranch = (name) => {
    if (branchNameInput === '') {
      setIsBranchEmpty(true);
      setOpenBranchCreate(true);
    } else {
      if (branchExists) {
        setIsBranchExists(true);
        setOpenBranchCreate(true);
      } else {
        const entity = {
          name,
          managerApproval: false,
          escalateApproval: false,
          customerApproval: false,
          bank: bankEntity,
        };
        dispatch(createEntity(entity));
        setOpenBranchCreate(false);
        setBranchNameInput('');
        setIsBranchEmpty(false);
        setIsBranchExists(false);
      }
    }
  };


  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "rgba(255,255,255,0)",
      color: theme.palette.common.white,
      fontWeight: 'bold',
      letterSpacing: 1,
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right',
    },
  }));

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const theme = useTheme();
  const [branchValue, setBranchValue] = React.useState("");
  const [idValue, setIdValue] = React.useState("");
  const [active, setActive] = React.useState(false);
  const [areaManagerId, setAreaManagerId] = React.useState('');
  const handleChange = event => {
    const selectedBranch = event.target.value;
    const branchArray = selectedBranch.split("|");
    const name = String(branchArray[0]);
    const id = String(branchArray[1]);
    setAreaManagerId(String(branchArray[2]));
    dispatch(getBranch(Number(id)));
    const selectValue = JSON.parse(window.localStorage.getItem('selectedBranch'));
    setActive(true);
    setBranchValue(name);
    setIdValue(id);
    window.localStorage.setItem('selectedBranch', JSON.stringify(name));
  };

  const [switchState, setSwitchState] = React.useState({
    required: true,
    notRequired: false,
  });
  const handleSwitchManager = branch => () =>
    !branch.managerApproval ?
      dispatch(
        updateEntity({
          ...branch,
          managerApproval: true,
        })
      ) :
      dispatch(
        updateEntity({
          ...branch,
          managerApproval: false,
          escalateApproval: false
        })
      );
  const handleSwitchEscalate = branch => () =>
    branch.managerApproval &&
    dispatch(
      updateEntity({
        ...branch,
        escalateApproval: !branch.escalateApproval,
      })
    );
  const handleSwitchCustomer = branch => () =>
    dispatch(
      updateEntity({
        ...branch,
        customerApproval: !branch.customerApproval,
      })
    );

  function toEnglishDigits(str) {
    let e = '۰'.charCodeAt(0);

    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  }

  const handleToValue = event => {
    const bankFeeToValue = toEnglishDigits(event.target.value);
    setNoDecimalAllowed(true);

    if (bankFeeToValue.toString().charAt(bankFeeToValue.length - 1) !== '.' && isNumber(bankFeeToValue.toString().charAt(bankFeeToValue.length - 1)) && bankFeeToValue.length < 20) {
      const enteredToValue = bankFeeToValue.replace(/,/g, '');
      setCheckedToValue(enteredToValue);
      const cleanedToValue = enteredToValue.replace(/[^0-9.]+/g, "");
      setToValue(cleanedToValue);
      const displayedToValue = Number(cleanedToValue).toLocaleString();
      setToValueDisplayed(displayedToValue);
    } else if (bankFeeToValue.toString().charAt(bankFeeToValue.length - 1) === '.' && bankFeeToValue.length < 20) {
      setNoDecimalAllowed(false);
      setToValue(bankFeeToValue.slice(0, -1));
      setToValueDisplayed(bankFeeToValue.slice(0, -1));
    } else {
      setToValue(bankFeeToValue.slice(0, -1));
      setToValueDisplayed(bankFeeToValue.slice(0, -1));
    }

  }

  const handleEachAmountValue = event => {
    const bankFeeToValue = toEnglishDigits(event.target.value);
    setNoDecimalAllowed(true);

    if (bankFeeToValue.toString().charAt(bankFeeToValue.length - 1) !== '.' && isNumber(bankFeeToValue.toString().charAt(bankFeeToValue.length - 1)) && bankFeeToValue.length < 20) {
      const enteredToValue = bankFeeToValue.replace(/,/g, '');
      setCheckedEachAmountValue(enteredToValue);
      const cleanedToValue = enteredToValue.replace(/[^0-9.]+/g, "");
      setEachAmountValue(cleanedToValue);
    } else if (bankFeeToValue.toString().charAt(bankFeeToValue.length - 1) === '.' && bankFeeToValue.length < 20) {
      setNoDecimalAllowed(false);
      setEachAmountValue(bankFeeToValue.slice(0, -1));
    } else {
      setEachAmountValue(bankFeeToValue.slice(0, -1));
    }

  }


  const handleAmount = event => {
    const bankFeeAmountValue = toEnglishDigits(event.target.value);
    // console.warn("bankFeeAmountValue"+bankFeeAmountValue);
    const bankFeeDecimalPoints = bankFeeAmountValue.toString().split(".");
    // console.warn("bankFeeDecimalPoints"+bankFeeDecimalPoints);
    if (bankFeeAmountValue.toString().charAt(bankFeeAmountValue.length - 1) !== '.' && isNumber(bankFeeAmountValue.toString().charAt(bankFeeAmountValue.length - 1)) && bankFeeAmountValue.length < 20) {
      const enteredAmount = bankFeeAmountValue.replace(/,/g, '');
      // console.warn("enteredAmount"+enteredAmount);
      setCheckedAmount(enteredAmount);
      const cleanedAmount = enteredAmount.replace(/[^0-9.]+/g, "");
      // console.warn("cleanedAmount"+cleanedAmount);
      setAmount(cleanedAmount);
      const displayedAmount = Number(cleanedAmount).toLocaleString();
      // console.warn("displayedAmount"+displayedAmount);
      setAmountDisplayed(displayedAmount);
    } else if (bankFeeAmountValue.toString().charAt(bankFeeAmountValue.length - 1) === '.' && bankFeeDecimalPoints.length === 2 && bankFeeAmountValue.length < 20) {
      setAmount(bankFeeAmountValue);
      setAmountDisplayed(bankFeeAmountValue);
    } else {
      setAmount(bankFeeAmountValue.slice(0, -1));
      setAmountDisplayed(bankFeeAmountValue.slice(0, -1));
    }
  }

  // const handleAmount = (event) => {
  //   const bankFeeAmountValue = toEnglishDigits(event.target.value);
  //   const cleanedAmount = bankFeeAmountValue.replace(/[^0-9]+/g, "");
  //
  //   if (isNumber(cleanedAmount) && cleanedAmount.length < 20) {
  //     setCheckedAmount(cleanedAmount);
  //     setAmount(cleanedAmount);
  //
  //     const displayedAmount = Number(cleanedAmount).toLocaleString();
  //     setAmountDisplayed(displayedAmount);
  //   } else {
  //     const validAmount = bankFeeAmountValue.slice(0, -1).replace(/[^0-9]+/g, "");
  //     setAmount(validAmount);
  //     setAmountDisplayed(Number(validAmount).toLocaleString());
  //   }
  // };


  const handleFeeCollapse = () => {
    setFeeCollapse((prev) => !prev);
  };

  const handleReportCollapse = () => {
    setReportCollapse((prev) => !prev);
  };

  const handleApiSettingsCollapse = () => {
    setApiSettingsCollapse((prev) => !prev);
  };

  const handleValueSellConfirmationEndpoint = event => {
    const changeSellConfirmationEndpoint = event.target.value;
    setValueSellConfirmationEndpoint(changeSellConfirmationEndpoint);
  };

  const handleValueVendorName = event => {
    const changeVendorName = event.target.value;
    setValueVendorName(changeVendorName);
  };

  const handleValueVendorCif = event => {
    const changeVendorCif = event.target.value;
    setValueVendorCif(changeVendorCif);
  };

  const handleApiSettings = async () => {

    const entity = {
      ...bankApiSettingEntity,
      bankId:bankEntity?.id,
      sellConfirmationEndpoint: valueSellConfirmationEndpoint,
      vendorName: valueVendorName,
      vendorCif: valueVendorCif,
    };

    await dispatch(createBankApiSetting(entity))
      .then(res => {
        const data = (res.payload as { data: { sellConfirmationEndpoint?: string; vendorName?: string; vendorCif?: string } })?.data;
        setValueSellConfirmationEndpoint(data?.sellConfirmationEndpoint || "");
        setValueVendorName(data?.vendorName || "");
        setValueVendorCif(data?.vendorCif || "");
      })
      .catch(error => {
        setValueSellConfirmationEndpoint("");
        setValueVendorName("");
        setValueVendorCif("");
      });
  };

  const [reportSettingsState, setReportSettingsState] = React.useState({
    bankStaffRole: bankEntity?.reportForBankStaff,
    branchManagerRole: bankEntity?.reportForBranchManager,
    areaManagerRole: bankEntity?.reportForAreaManager,
    suppliersDepartmentRole: true,
    operationsDepartmentRole: true,
    bankManagerRole: bankEntity?.reportForBankManager,
  });

  const handleReportSettingsChange = (event) => {
    setReportSettingsState({
      ...reportSettingsState,
      [event.target.name]: event.target.checked,
    });
  };

  const [reportSwitchState, setReportSwitchState] = React.useState({
    available: true,
    unavailable: false,
  });

  const handleBankStaffReport = () => {
    const updateBankStaffReport = { ...bankEntity, reportForBankStaff: !bankEntity.reportForBankStaff }
    dispatch(updateBank(updateBankStaffReport));
  };

  const handleBranchManagerReport = () => {
    const updateBranchManagerReport = { ...bankEntity, reportForBranchManager: !bankEntity.reportForBranchManager }
    dispatch(updateBank(updateBranchManagerReport));
  };

  const handleAreaManagerReport = () => {
    const updateAreaManagerReport = { ...bankEntity, reportForAreaManager: !bankEntity.reportForAreaManager }
    dispatch(updateBank(updateAreaManagerReport));
  };

  const handleBankManagerReport = () => {
    const updateBankManagerReport = { ...bankEntity, reportForBankManager: !bankEntity.reportForBankManager }
    dispatch(updateBank(updateBankManagerReport));
  };

  const handleAuditorReport = () => {
    const updateAuditorReport = { ...bankEntity, reportForAuditor: !bankEntity.reportForAuditor }
    dispatch(updateBank(updateAuditorReport));
  };

  useEffect(() => {
    if (areaManagerId !== '') {
      if (account?.authorities.includes(AUTHORITIES.ADMIN))
        dispatch(getUserByIdAsAdmin(+areaManagerId));
      else
        dispatch(getUserByIdAsOtherRoles(+areaManagerId));
    }
  }, [areaManagerId]);

  useEffect(() => {
    idValue !== '' && dispatch(getAccountsByBranchId(+idValue));
  }, [branchList, idValue]);
  useEffect(() => {
    setBranchName(branchValue);
  }, [branchValue]);
  selectedBranchEntity = branchList.find(branch => branch.id === +idValue);
  const useStyles = makeStyles(() =>
    createStyles({
      dialogArabic: {
        margin: 0,
        marginLeft: '40px',
      },
      avatar: {
        width: '40px',
        height: '40px',
        fontSize: '16px',
        [theme.breakpoints.down("xs")]: {
          width: '30px',
          height: '30px',
          fontSize: '12px',
        },
      },
      listAvartarItem: {
        marginRight: '0px',
        [theme.breakpoints.down("xs")]: {
          marginLeft: '12px'
        },
      }

    })
  );

  const imgFromDb = (bankEntity?.imgURL);
  const imgStringToNumber = imgFromDb?.split(',').map(i => Number(i));
  const decodedImgString = String.fromCharCode.apply(null, imgStringToNumber);
  const imageFromS3 = `https://${s3BucketName}.s3.me-south-1.amazonaws.com/bankLogo/${bankEntity?.imgURL}`

  const classes = useStyles();
  let responsiveFont = createTheme();
  responsiveFont = responsiveFontSizes(responsiveFont);

  useEffect(() => {
    setLoadingSpinner(false);
    setTimeout(() => {
      setLoadingSpinner(true);
    }, 2500);
  }, []);

  function bankLogo() {
    if (bankEntity?.imgURL !== null && bankEntity?.imgURL !== undefined && s3BucketName) {
      return (
        <img className="bank-img" src={imageFromS3} alt="bankLogo" />
      )
    } else if (bankEntity?.imgURL === null) {
      return (
        <img className="bank-img" src="content/images/bankLogoDefault.png" alt="bankLogo" />
      )
    } else if (loadingSpinner === false) {
      return (
        <div hidden={loadingSpinner}>
          <img
            src="../../content/images/loading.gif"
            style={{ opacity: '40%', width: '60%', height: 'auto' }}
            hidden={loadingSpinner}
            />
        </div>
      )
    }
  }

  useEffect(() => {
    if (bankEntity && bankEntity.currencyId) {
      setSelectedCurrency(bankEntity.currencyId);
    }
  }, [bankEntity]);

  useEffect(() => {
    if (oldSelectedFeeType !== selectedFeeType) {
      setUpdatedFeeArray([]);
    } else {
      setUpdatedFeeArray(checkArrayDifferences(oldFormStateFee, formStateFee));
    }
  }, [oldSelectedFeeType, selectedFeeType, oldFormStateFee, formStateFee]);

  useEffect(() => {
    setUpdatedCommissionArray(checkArrayDifferences(oldFormStateCommission, formStateCommission));
  }, [oldFormStateCommission, formStateCommission]);

  useEffect(() => {
    // filtered list commission
    const filteredCommissionList = comexBankFeeList?.filter(c =>
      c?.currencyId?.toLocaleString() === selectedCurrency.toLocaleString() &&
      c?.feeType === 'commission'
    );
    setFilteredComexBankCommissionList(filteredCommissionList);

    // filtered list fee
    const filteredFeeList = comexBankFeeList?.filter(c =>
      (c?.currencyId?.toLocaleString() === selectedCurrency.toLocaleString() || c?.currencyId === null) &&
      c?.feeType !== 'commission'
    );
    setFilteredComexBankFeeList(filteredFeeList);

    // grouped all currency by fromTo value
    const newGroupedData = newComexBankFeeList?.reduce((acc, current) => {
      if (current?.currencyId === null) {
        acc?.push({ fromTo: current?.fromTo, fee: [{ id: current?.id, amount: current?.amount, currencyId: null }] });
      } else {
        const index = acc?.findIndex(group => group?.fromTo === current?.fromTo);
        if (index === -1) {
          acc?.push({ fromTo: current?.fromTo, fee: [{ id: current?.id, amount: current?.amount, currencyId: current?.currencyId }] });
        } else {
          acc[index]?.fee?.push({ id: current?.id, amount: current?.amount, currencyId: current?.currencyId });
        }
      }
      return acc;
    }, []);
    setGroupedData(newGroupedData);

  }, [selectedCurrency, comexBankFeeList]);

  const handleChangeCurrencyDropdown = (event) => {
    setSelectedCurrency(event.target.value);
  };

  const handleChangeFeeAllCurrency = (id: string) => {
    const itemIndex = availableCurrency.findIndex(item => item.id === id);
    return event => {
      const value = toEnglishDigits(event.target.value);

      const updatedFormStateFee = formStateFee.map(fee =>
        fee.currencyId === id ? { ...fee, amount: value } : fee
      );

      // Check if the fee was found and initialized; if not, add it
      if (!updatedFormStateFee.some(fee => fee.currencyId === id)) {
        updatedFormStateFee.push({ currencyId: id, amount: value });
      }
      setFormStateFee(updatedFormStateFee);

      const updatedFormStateCommission = formStateCommission.map(commission =>
        commission.currencyId === id ? { ...commission, amount: value } : commission
      );

      // Check if the commission was found and initialized; if not, add it
      if (!updatedFormStateCommission.some(commission => commission.currencyId === id)) {
        updatedFormStateCommission.push({ currencyId: id, amount: value });
      }
      setFormStateCommission(updatedFormStateCommission);

    };
  };

  const handleDigits = (event, id) => {
    const itemIndex = availableCurrency.findIndex(item => item.id === id);
    const value = toEnglishDigits(event.target.value);
    const cleanedValue = value.replace(/[^0-9]+/g, "");

    const updatedFormStateFee = formStateFee.map(fee =>
      fee.currencyId === id ? { ...fee, amount: cleanedValue } : fee
    );

    // Check if the fee was found and initialized; if not, add it
    if (!updatedFormStateFee.some(fee => fee.currencyId === id)) {
      updatedFormStateFee.push({ currencyId: id, amount: cleanedValue });
    }
    setFormStateFee(updatedFormStateFee);

    const updatedFormStateCommission = formStateCommission.map(commission =>
      commission.currencyId === id ? { ...commission, amount: cleanedValue } : commission
    );

    // Check if the commission was found and initialized; if not, add it
    if (!updatedFormStateCommission.some(commission => commission.currencyId === id)) {
      updatedFormStateCommission.push({ currencyId: id, amount: cleanedValue });
    }
    setFormStateCommission(updatedFormStateCommission);
  };

  useEffect(() => {
    if(isNew){
      filterUniqueFromAmountFee();
    }
  }, [comexBankFeeList]);

  const filterUniqueFromAmountFee = () => {
    const onlyFeeList = comexBankFeeList?.filter(c => c?.feeType !== "commission");
    const unique = onlyFeeList?.reduce((uniqueItems, item) => {
      if (!uniqueItems?.some(i => i?.fromTo === item?.fromTo)) {
        uniqueItems?.push(item);
      }
      return uniqueItems;
    }, []);

    setUniqueFromAmountFee(unique);
  };

  function findCurrencyName(entity) {
    const find = availableCurrency?.find(c => c?.id === entity);
    return find?.name;
  }

  function feeAllCurrencyForm() {
    return (
      <TableContainer sx={{ maxHeight: 160 }}>
        <Table sx={{ minWidth: 400, '& th, & td': { border: 0 } }} size="small">
          <TableBody>
            {availableCurrency?.map((item, index) => {
              const matchedFee = formStateFee.find(fee => fee.currencyId === item.id);
              const matchedCommission = formStateCommission.find(commission => commission.currencyId === item.id);

              return(
              <TableRow key={item?.id}>
                <TableCell width="20%" component="th" scope="row"
                           align={Storage.session.get('locale') === 'ar-ly' ? "right" : "left"}>
                  {item?.name}
                </TableCell>
                <StyledTableCell />
                <TableCell align="right">
                  {updateCommission === true ? (
                    <Input
                      style={{ width: '100%' }}
                      type="text"
                      placeholder="0"
                      onChange={event => {
                        handleChangeFeeAllCurrency(item?.id)(event);
                        handleDigits(event, item?.id);
                      }}
                      value={matchedFee ? matchedFee.amount : ""}
                    />
                  ) : (
                    <Input
                      style={{ width: '100%' }}
                      type="text"
                      placeholder="0"
                      onChange={event => {
                        handleChangeFeeAllCurrency(item?.id)(event);
                        handleDigits(event, item?.id);
                      }}
                      value={matchedCommission ? matchedCommission.amount : ""}
                    />
                  )}
                </TableCell>
              </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  function feeAmountField() {
    if (selectedFeeType === 'Lump Sum') {
      return feeAllCurrencyForm();
    } else {
      return (
        <ValidatedField
          // label={translate('comexApp.bank.details.amount')}
          register={register}
          error={errors?.amount}
          id="comex-bank-fee-amount"
          name="amount"
          data-cy="amount"
          type="text"
          onChange={handleAmount}
          value={amount}
          validate={{
            required: { value: true, message: translate('entity.validation.required') },
            validate: v => isNumber(checkedAmount) || translate('entity.validation.number'),
          }}
        />
      )
    }
  }

  return (
    <Container>
      <ThemeProvider theme={responsiveFont}>
        <div className="bank-details-heading-section mt-4">
          <Row>
            <Col sm="8" xs={12}>
              <div className="mb-4">
                <Typography variant="h4" component="div" data-cy="bankDetailPageHeading" className="mb-4">
                  {bankEntity?.name}
                </Typography>

                <Typography component="div" data-cy="bankStartDate" className='"mb-4'>
                  {translate("comexApp.bank.details.startDate")}: {formatDate(bankEntity?.createdDate)}
                </Typography>

                <Typography variant="h6" component="div" data-cy="bankDetailPageHeading" className="mb-4">
                  {bankEntity?.description}
                </Typography>
              </div>
              <Button onClick={handleEditBank} color="primary"
                      hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                <FontAwesomeIcon icon="pencil-alt"/>
                <Translate contentKey="comexApp.bank.details.editBtn"/>
              </Button>
            </Col>
            <Col sm="4" xs={12}>
              <Box sx={{justifyContent: {sm: 'flex-end'}}} className='bank-img-box'>
                {bankLogo()}
              </Box>
            </Col>
          </Row>
        </div>

        <div className="collapse-section">
          <Grid container className="align-items-center">
            <Grid item xs={10} sm={11} md={11.5}>
              <Typography variant="h5" component="div" data-cy="bankFeeHeading">
                {translate('comexApp.bank.details.commissionAndFee')}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} md={0.5}>
              <IconButton aria-label="collapse" onClick={handleFeeCollapse}>
                {!feeCollapse ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={!feeCollapse}>

            <br/>
            <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
              <div>
                <FormControl sx={{width: {md: 300, xs: 280}, display: 'flex', justifyContent: 'flex-start'}}>
                  <Input
                    type='select'
                    width={"80%"}
                    onChange={handleChangeCurrencyDropdown}
                    value={selectedCurrency}
                  >
                    {availableCurrency?.map((c, index) => {
                      return (
                        <option
                          key={index}
                          value={c?.id}
                        >
                          {c?.name}
                        </option>
                      );
                    })}
                  </Input>
                </FormControl>
              </div>
            </Box>
            <Paper className="paper-table my-4">
              <TableContainer sx={{maxHeight: 440}}>
                <Table className="fee-table" aria-label="sticky table">
                  <TableHead className={bankEntity?.commission ? "enhanced-table-bank" : "disable-table-bank"}
                             id='commissionTable'>
                    <TableRow>
                      <StyledTableCell><Translate contentKey="comexApp.bank.details.table.active"/></StyledTableCell>
                      <StyledTableCell><Translate
                        contentKey="comexApp.bank.details.table.commissionForEachAmount"/></StyledTableCell>
                      <StyledTableCell><Translate
                        contentKey="comexApp.bank.details.table.commissionAmount"/></StyledTableCell>
                      <StyledTableCell></StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredComexBankCommissionList && filteredComexBankCommissionList
                      .map((item, i) => (
                        <TableRow key={item.id}>
                          <StyledTableCell>
                            <Checkbox size="small" checked={commissionData} onChange={handleCommission}/>
                          </StyledTableCell>
                          <StyledTableCell>
                            {item?.fromTo?.toLocaleString()}
                            {/* <Input */}
                            {/*   id="commissionForEach" */}
                            {/*   type="text" */}
                            {/*   value={commissionForEachAmt} */}
                            {/*   onChange={handleCommissionForEachAmount} */}
                            {/*   disabled={updateCommission} */}
                            {/*   autoFocus={isCommissionForEachFieldClicked} */}
                            {/*   /> */}
                          </StyledTableCell>

                          <StyledTableCell>
                            {item?.amount?.toLocaleString() + ' ' + findCurrencyName(item?.currencyId)}
                            {/* {commissionAmt} */}
                            {/* <Input */}
                            {/*   id="commissionAmount" */}
                            {/*   type="text" */}
                            {/*   value={commissionAmt} */}
                            {/*   onChange={handleCommissionAmount} */}
                            {/*   disabled={updateCommission} */}
                            {/*   autoFocus={isCommissionAmountFieldClicked} */}
                            {/*   /> */}
                          </StyledTableCell>
                          <StyledTableCell
                            hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                            <div className="btn-group flex-btn-group-container"
                                 style={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Tooltip title={translate('comexApp.bank.details.table.editIcon')}>
                              <span>
                                <IconButton
                                  className="px-4"
                                  aria-label="edit"
                                  size="small"
                                  onClick={() => handleClickOpenCommission(item?.id)}
                                  value={item?.id}
                                  disabled={updatingBank || updateCommission}
                                >
                                  <EditIcon fontSize="small"/>
                                </IconButton>
                              </span>
                              </Tooltip>
                              <Tooltip title={translate('comexApp.bank.details.table.deleteIcon')}>
                              <span>
                                <IconButton
                                  className="px-4"
                                  aria-label="delete"
                                  size="small"
                                  color="error"
                                  onClick={() => setOpenDelete(item.id)}
                                  disabled={updatingBank || updateCommission}
                                >
                                  <DeleteIcon fontSize="small"/>
                                </IconButton>
                              </span>
                              </Tooltip>
                            </div>
                          </StyledTableCell>
                          {/* <StyledTableCell> */}
                          {/*   <Button onClick={updateCommissionBank} color="primary" type="button" disabled={updatingBank || updateCommission}> */}
                          {/*     <Translate contentKey='comexApp.bank.details.update' /> */}
                          {/*   </Button> */}
                          {/* </StyledTableCell> */}
                        </TableRow>
                      ))}
                    {filteredComexBankCommissionList?.length === 0 && (
                      <TableRow>
                        <StyledTableCell>
                          <Checkbox size="small" checked={commissionData} onChange={handleCommission}/>
                        </StyledTableCell>
                        <StyledTableCell>0</StyledTableCell>
                        <StyledTableCell>0</StyledTableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                color="primary"
                disabled={updatingBank || updateCommission || filteredComexBankCommissionList.length !== 0}
                onClick={() => handleClickOpenCommission(0)}
                value={0}
                hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                <FontAwesomeIcon icon="plus"/> <Translate contentKey="comexApp.bank.details.update"/>
              </Button>
            </Box>
            {
              openDelete ?
                <BankDetailFeeDeleteDialog id={openDelete} showModal={openDelete} closeModal={setOpenDelete}/> : null
            }

            {loading ? (
              <Dialog open={openCommission} onClose={handleClose}>
                <DialogTitle style={{fontWeight: "bold"}}>Loading</DialogTitle>
              </Dialog>
            ) : (
              <Draggable>
                <Dialog open={openCommission} onClose={handleClose} hideBackdrop={true} maxWidth={maxWidth}
                        fullWidth={fullWidth}
                        sx={Storage.session.get('locale') === 'ar-ly' ? {marginLeft: '40px'} : null}>

                  <DialogTitle style={{fontWeight: "bold", cursor: "move"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={10} sx={{display: 'flex', justifyContent: 'flex-start'}}>{isNew ? (
                        <Translate contentKey='comexApp.bank.details.addNewFeeRange'></Translate>

                      ) : (<Translate contentKey='comexApp.bank.details.updateFeeRange'></Translate>
                      )}</Grid></Grid></DialogTitle>
                  <Form onSubmit={handleSubmit(updateNewCommissionBank)}>
                    <DialogContent>
                      <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            top: 10,
                          }}
                        >
                          <CloseIcon/>
                        </IconButton>
                      </Grid>
                      <DialogContentText style={{color: "black"}} className='mb-3' component={'span'}>
                        {!isNew ? (
                          <ValidatedField
                            name="id"
                            register={register}
                            hidden
                            readOnly
                            id="comex-bank-fee-id"
                            label={translate('global.field.id')}
                            validate={{required: true}}
                          />
                        ) : null}
                        <ValidatedField
                          label={translate("comexApp.bank.details.table.commissionForEachAmount")}
                          id="comex-bank-fee-fromTo"
                          register={register}
                          error={errors?.fromTo}
                          name="commissionEachAmount"
                          data-cy="commissionEachAmount"
                          type="text"
                          onChange={handleEachAmountValue}
                          // value={commissionForEachAmt}
                          // value={checkedCommissionForEachAmt}
                          value={eachAmountValue}
                          // validate={{
                          //   required: { value: true, message: translate('entity.validation.required') },
                          // }}
                        />
                        <div className="bank-fee-no-decimal-error" hidden={noDecimalAllowed}>
                          {translate('comexApp.bank.validation.noDecimalAllowed')}
                        </div>

                        <br/>
                        <div>
                          <Label>{translate("comexApp.bank.details.table.commissionAmount")}</Label>
                          {feeAllCurrencyForm()}
                        </div>
                      </DialogContentText>

                    </DialogContent>
                    <DialogActions sx={{justifyContent: "flex-end"}} className='mx-3 mb-3'>
                      <Button onClick={handleClose}><Translate contentKey='comexApp.bank.details.cancel'/></Button>
                      <Button color="primary" type="submit"
                              style={Storage.session.get('locale') === 'en' ? {marginLeft: '10px'} : {marginRight: '10px'}}
                              disabled={updating}>
                        {isNew ? (<Translate contentKey='comexApp.bank.details.add'/>) : (
                          <Translate contentKey='comexApp.bank.details.update'/>)}</Button>
                    </DialogActions> </Form>
                </Dialog>
              </Draggable>
            )}
            &nbsp;

            <Paper className="paper-table my-4">
              <TableContainer sx={{maxHeight: 440}}>
                <Table className="fee-table" aria-label="sticky table">
                  <TableHead className={bankEntity?.commission ? "disable-table-bank" : "enhanced-table-bank"}
                             id='feeTable'>
                    <TableRow>
                      <StyledTableCell><Translate contentKey="comexApp.bank.details.table.from"/></StyledTableCell>
                      <StyledTableCell><Translate contentKey="comexApp.bank.details.table.to"/></StyledTableCell>
                      <StyledTableCell><Translate contentKey="comexApp.bank.details.table.feeType"/></StyledTableCell>
                      <StyledTableCell><Translate contentKey="comexApp.bank.details.table.amount"/></StyledTableCell>
                      <StyledTableCell
                        hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}/>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredComexBankFeeList && filteredComexBankFeeList
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((comexBankFee, i) => (
                        <TableRow key={`entity-${i}`}>{i === 0 ?

                          (<StyledTableCell>1&nbsp;</StyledTableCell>) : (
                            <StyledTableCell>{(groupedData[i - 1]?.fromTo + 1).toLocaleString()}</StyledTableCell>)}
                          <StyledTableCell>{comexBankFee?.fromTo?.toLocaleString()}</StyledTableCell>
                          <StyledTableCell>{comexBankFee?.feeType}</StyledTableCell>
                          <StyledTableCell>{comexBankFee?.feeType === 'Percentage' ? comexBankFee?.amount?.toLocaleString() + '%' : comexBankFee?.amount?.toLocaleString() + ' ' + findCurrencyName(comexBankFee?.currencyId)}</StyledTableCell>
                          <StyledTableCell
                            hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                            <div className="btn-group flex-btn-group-container"
                                 style={{display: 'flex', justifyContent: 'flex-end'}}>
                              <Tooltip title={translate('comexApp.bank.details.table.editIcon')}>
                              <span>
                                <IconButton
                                  className="px-4"
                                  aria-label="edit"
                                  size="small"
                                  onClick={() => handleClickOpen(comexBankFee?.id, comexBankFee?.fromTo)}
                                  value={comexBankFee?.id}
                                  disabled={!updateCommission}
                                >
                                  <EditIcon fontSize="small"/>
                                </IconButton>
                              </span>
                              </Tooltip>
                              <Tooltip title={translate('comexApp.bank.details.table.deleteIcon')}>
                              <span>
                                <IconButton
                                  className="px-4"
                                  aria-label="delete"
                                  size="small"
                                  color="error"
                                  onClick={() => setOpenDelete(comexBankFee?.id)}
                                  disabled={!updateCommission}
                                >
                                  <DeleteIcon fontSize="small"/>
                                </IconButton>
                              </span>
                              </Tooltip>
                            </div>
                          </StyledTableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                className={Storage.session.get('locale') === 'en' ? "table-pagination" : "table-pagination-arabic"}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={filteredComexBankFeeList?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage={translate('home.table.rowsPerPage')}
              />
            </Paper>
            <Typography variant="body2" className="mt-2 mb-3">
              <Translate contentKey="comexApp.bank.details.table.feeDetail"/>
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button color="primary" disabled={!updateCommission} onClick={() => handleClickOpen(0, 0)} value={0}
                      hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                <FontAwesomeIcon icon="plus"/> <Translate contentKey="comexApp.bank.details.table.addFeeBtn"/>
              </Button>
            </Box>
            {
              openDelete ?
                <BankDetailFeeDeleteDialog id={openDelete} showModal={openDelete} closeModal={setOpenDelete}/> : null
            }

            {loading ? (
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle style={{fontWeight: "bold"}}>Loading</DialogTitle>
              </Dialog>
            ) : (
              <Draggable>
                <Dialog open={open} onClose={handleClose} hideBackdrop={true} maxWidth={maxWidth} fullWidth={fullWidth}
                        sx={Storage.session.get('locale') === 'ar-ly' ? {marginLeft: '40px'} : null}>

                  <DialogTitle style={{fontWeight: "bold", cursor: "move"}}>
                    <Grid container spacing={2}>
                      <Grid item xs={10} sx={{display: 'flex', justifyContent: 'flex-start'}}>{isNew ? (
                        <Translate contentKey='comexApp.bank.details.addNewFeeRange'></Translate>

                      ) : (<Translate contentKey='comexApp.bank.details.updateFeeRange'></Translate>
                      )}</Grid></Grid></DialogTitle>
                  <Form onSubmit={handleSubmit(saveEntity)}>
                    <DialogContent>
                      <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton
                          aria-label="close"
                          onClick={handleClose}
                          sx={{
                            position: 'absolute',
                            top: 10,
                          }}
                        >
                          <CloseIcon/>
                        </IconButton>
                      </Grid>
                      <DialogContentText style={{color: "black"}} className='mb-3' component={'span'}>
                        {!isNew ? (
                          <ValidatedField
                            name="id"
                            register={register}
                            hidden
                            readOnly
                            id="comex-bank-fee-id"
                            label={translate('global.field.id')}
                            validate={{required: true}}
                          />
                        ) : null}
                        <Row className="justify-content-center">
                          <Col xs={6}>
                            <Label><Translate contentKey='comexApp.bank.details.from'/></Label>
                            <Input type="select" defaultValue={fromValue} onChange={handleFromToValidate}
                                   disabled={!isNew}>
                              <option value={1} key={1}>1</option>
                              {uniqueFromAmountFee
                                .map(fee => (
                                  <option value={(fee.fromTo + 1)} key={fee.fromTo + 1}>
                                    {(fee.fromTo + 1).toLocaleString()}
                                  </option>
                                ))}
                            </Input>
                          </Col>
                          <Col xs={6}>
                            <ValidatedField
                              label={translate('comexApp.bank.details.to')}
                              id="comex-bank-fee-fromTo"
                              register={register}
                              error={errors?.fromTo}
                              name="fromTo"
                              data-cy="fromTo"
                              type="text"
                              onChange={handleToValue}
                              value={toValue}
                              validate={{
                                required: {value: true, message: translate('entity.validation.required')},
                                validate: v => (checkedToValue > bankEntity?.transactionAmountLimit && translate('comexApp.bank.validation.toValidationMessage', {limit: bankEntity?.transactionAmountLimit})) ||
                                  (isNumber(checkedToValue) && checkedToValue > fromValue && checkedToValue <= toLimitValue || translate('entity.validation.number')
                                    && translate('comexApp.bank.validation.valueRange', {validateFromTo})),
                              }}
                            />
                            <div className="bank-fee-no-decimal-error"
                                 hidden={noDecimalAllowed}>{translate('comexApp.bank.validation.noDecimalAllowed')}</div>
                          </Col>
                        </Row>
                        <ValidatedField
                          label={translate('comexApp.bank.details.feeType')}
                          id="comex-bank-fee-feeType"
                          register={register}
                          error={errors?.feeType}
                          name="feeType"
                          data-cy="feeType"
                          type="select"
                          value={selectedFeeType}
                          onChange={(e) => setSelectedFeeType(e.target.value)}
                          validate={{
                            required: {value: true, message: translate('entity.validation.required')},
                          }}
                        >
                          <option value={'Lump Sum'} key={'Lump Sum'}
                                  defaultValue={'Lump Sum'}>{translate('comexApp.bank.details.lumpSum')}</option>
                          <option value={'Percentage'}
                                  key={'Percentage'}>{translate('comexApp.bank.details.percentage')}</option>
                        </ValidatedField>


                        <br/>
                        <div>
                          <Label>{translate('comexApp.bank.details.amount')}</Label>
                          {feeAmountField()}
                        </div>

                      </DialogContentText>

                    </DialogContent>
                    <DialogActions sx={{justifyContent: "flex-end"}} className='mx-3 mb-3'>
                      <Button onClick={handleClose}><Translate contentKey='comexApp.bank.details.cancel'/></Button>
                      <Button color="primary" type="submit"
                              style={Storage.session.get('locale') === 'en' ? {marginLeft: '10px'} : {marginRight: '10px'}}
                              disabled={updating}>
                        {isNew ? (<Translate contentKey='comexApp.bank.details.add'/>) : (
                          <Translate contentKey='comexApp.bank.details.update'/>)}</Button>
                    </DialogActions> </Form>
                </Dialog>
              </Draggable>
            )}
          </Collapse>
        </div>
        <Dialog open={openBranchEdit} onClose={handleEditBranchClose} maxWidth={maxWidth} fullWidth={fullWidth}
                sx={Storage.session.get('locale') === 'ar-ly' ? {marginLeft: '40px'} : null}>
          <DialogTitle style={{fontWeight: "bold"}}>
            <Translate contentKey={'comexApp.bank.details.branch.branchName'}/>
          </DialogTitle>
          <DialogContent>
            <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <IconButton
                aria-label="close"
                onClick={handleEditBranchClose}
                sx={{
                  position: 'absolute',
                  top: 10,
                }}
              >
                <CloseIcon/>
              </IconButton>
            </Grid>
            {openBranchEdit && (
              <Form onSubmit={handleBranchUpdate}>
                <ValidatedField
                  name="name"
                  id="branchName"
                  validate={{
                    required: {value: true, message: translate('entity.validation.required')},
                    validate: v =>
                      branchList.find(branch => branch?.name?.toLowerCase() === v.toLowerCase()) === undefined ||
                      translate('entity.validation.branchNameAlreadyExists'),
                  }}
                  value={branchName}
                  onChange={(e) => {
                    setBranchName(e.target.value)
                  }}
                />
                <DialogActions className="mx-3 mb-3">
                  <Button className='flex-end' onClick={handleEditBranchClose}><Translate
                    contentKey='comexApp.bank.details.cancel'/></Button>
                  <Button className='flex-end' color="primary" type="submit"
                          style={Storage.session.get('locale') === 'en' ? {marginLeft: '10px'} : {marginRight: '10px'}}
                          disabled={updating}>
                    <Translate contentKey='comexApp.bank.details.save'/></Button>
                </DialogActions>

              </Form>

            )}
          </DialogContent>


        </Dialog>

        <Dialog open={openBranchCreate} onClose={handleCreateBranchClose} maxWidth={maxWidth} fullWidth={fullWidth}
                sx={Storage.session.get('locale') === 'ar-ly' ? {marginLeft: '40px'} : null}>
          <DialogTitle style={{fontWeight: "bold"}}> <Grid container spacing={2}>
            <Grid item xs={10} sx={{display: 'flex', justifyContent: 'flex-start'}}><Translate
              contentKey={'comexApp.bank.details.branch.createNewBranch'}/></Grid></Grid></DialogTitle>
          <form>
            <DialogContent>
              <DialogContentText component={'span'}>
                <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                  <IconButton
                    aria-label="close"
                    onClick={handleCreateBranchClose}
                    sx={{
                      position: 'absolute',
                      top: 10,
                    }}
                  >
                    <CloseIcon/>
                  </IconButton>
                </Grid>

                <ValidatedField
                  label={translate('comexApp.branch.name')}
                  id="branch-name"
                  name="name"
                  data-cy="name"
                  type="text"
                  register={register}
                  value={branchNameInput}
                  onChange={(e) => setBranchNameInput(e.target.value)}
                  invalid={isBranchEmpty}
                  validate={{
                    required: {value: true, message: translate('entity.validation.required')},
                  }}
                />
                <Label hidden={!isBranchEmpty} style={{
                  color: "red",
                  fontSize: 14,
                  fontWeight: 400
                }}>{translate('entity.validation.required')}</Label>
                <Label hidden={!isBranchExists} style={{
                  color: "red",
                  fontSize: 14,
                  fontWeight: 400
                }}>{translate('entity.validation.branchNameAlreadyExists')}</Label>
              </DialogContentText>
            </DialogContent>
            <DialogActions className="mx-3 mb-3">
              <Button onClick={handleCreateBranchClose}><Translate
                contentKey='comexApp.bank.details.cancel'/></Button>
              <Button onClick={() => handleCreateBranch(branchNameInput)} color="primary" type="button"
                      style={Storage.session.get('locale') === 'en' ? {marginLeft: '10px'} : {marginRight: '10px'}}
                      disabled={updating}>
                <Translate contentKey='comexApp.bank.details.save'/></Button>
            </DialogActions>
          </form>
        </Dialog>

        <div className="collapse-section">
          <Grid container className="align-items-center">
            <Grid item xs={10} sm={11} md={11.5}>
              <Typography variant="h5" component="div" data-cy="bankFeeHeading">
                {translate('comexApp.bank.details.reportSettings.title')}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} md={0.5}>
              <IconButton aria-label="collapse" onClick={handleReportCollapse}>
                {reportCollapse ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={reportCollapse}>
            <TableContainer className="my-3" sx={{maxHeight: 320}}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell style={{borderBottom: "none"}}>
                      <Grid container sx={{alignItems: "center"}}>
                        <Grid item sm={10} md={10}>
                          <Typography variant="body1" component="div" style={{fontWeight: 700}}
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.bankStaff.title')}
                          </Typography>
                          <Typography variant="body2" component="div" width="65%"
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.bankStaff.desc')}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} sx={{display: "inline-flex", justifyContent: "center"}}>
                          <Tooltip title={bankEntity?.reportForBankStaff ?
                            translate('comexApp.bank.details.reportSettings.available') : translate('comexApp.bank.details.reportSettings.unavailable')}>
                            <Switch
                              checked={bankEntity?.reportForBankStaff ? reportSwitchState.available : reportSwitchState.unavailable}
                              onChange={handleBankStaffReport}
                              name="reportForBankStaff"
                              disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottom: "none"}}>
                      <Grid container sx={{alignItems: "center"}}>
                        <Grid item sm={10} md={10}>
                          <Typography variant="body1" component="div" style={{fontWeight: 700}}
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.branchManager.title')}
                          </Typography>
                          <Typography variant="body2" component="div" width="65%"
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.branchManager.desc')}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} sx={{display: "inline-flex", justifyContent: "center"}}>
                          <Tooltip title={bankEntity?.reportForBranchManager ?
                            translate('comexApp.bank.details.reportSettings.available') : translate('comexApp.bank.details.reportSettings.unavailable')}>
                            <Switch
                              checked={bankEntity?.reportForBranchManager ? reportSwitchState.available : reportSwitchState.unavailable}
                              onChange={handleBranchManagerReport}
                              name="reportForBranchManager"
                              disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottom: "none"}}>
                      <Grid container sx={{alignItems: "center"}}>
                        <Grid item sm={10} md={10}>
                          <Typography variant="body1" component="div" style={{fontWeight: 700}}
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.areaManager.title')}
                          </Typography>
                          <Typography variant="body2" component="div" width="65%"
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.areaManager.desc')}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} sx={{display: "inline-flex", justifyContent: "center"}}>
                          <Tooltip title={bankEntity?.reportForAreaManager ?
                            translate('comexApp.bank.details.reportSettings.available') : translate('comexApp.bank.details.reportSettings.unavailable')}>
                            <Switch
                              checked={bankEntity?.reportForAreaManager ? reportSwitchState.available : reportSwitchState.unavailable}
                              onChange={handleAreaManagerReport}
                              name="reportForAreaManager"
                              disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottom: "none"}}>
                      <Grid container sx={{alignItems: "center"}}>
                        <Grid item sm={10} md={10}>
                          <Typography variant="body1" component="div" style={{fontWeight: 700}}
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.bankManager.title')}
                          </Typography>
                          <Typography variant="body2" component="div" width="65%"
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.bankManager.desc')}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} sx={{display: "inline-flex", justifyContent: "center"}}>
                          <Tooltip title={bankEntity?.reportForBankManager ?
                            translate('comexApp.bank.details.reportSettings.available') : translate('comexApp.bank.details.reportSettings.unavailable')}>
                            <Switch
                              checked={bankEntity?.reportForBankManager ? reportSwitchState.available : reportSwitchState.unavailable}
                              onChange={handleBankManagerReport}
                              name="reportForBankManager"
                              disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell style={{borderBottom: "none"}}>
                      <Grid container sx={{alignItems: "center"}}>
                        <Grid item sm={10} md={10}>
                          <Typography variant="body1" component="div" style={{fontWeight: 700}}
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.auditor.title')}
                          </Typography>
                          <Typography variant="body2" component="div" width="65%"
                                      align={Storage.session.get('locale') === 'ar-ly' ? 'right' : 'left'}>
                            {translate('comexApp.bank.details.reportSettings.auditor.desc')}
                          </Typography>
                        </Grid>
                        <Grid item sm={2} md={2} sx={{display: "inline-flex", justifyContent: "center"}}>
                          <Tooltip title={bankEntity?.reportForAuditor ?
                            translate('comexApp.bank.details.reportSettings.available') : translate('comexApp.bank.details.reportSettings.unavailable')}>
                            <Switch
                              checked={bankEntity?.reportForAuditor ? reportSwitchState.available : reportSwitchState.unavailable}
                              onChange={handleAuditorReport}
                              name="reportForAuditor"
                              disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Collapse>
        </div>

        <div className="collapse-section">
          <Grid container className="align-items-center">
            <Grid item xs={10} sm={11} md={11.5}>
              <Typography variant="h5" component="div" data-cy="bankFeeHeading">
                {translate('comexApp.bank.details.apiSettings.title')}
              </Typography>
            </Grid>
            <Grid item xs={2} sm={1} md={0.5}>
              <IconButton aria-label="collapse" onClick={handleApiSettingsCollapse}>
                {apiSettingsCollapse ? <ExpandLessRoundedIcon/> : <ExpandMoreRoundedIcon/>}
              </IconButton>
            </Grid>
          </Grid>
          <Collapse in={apiSettingsCollapse}>
            <br/>
            <ValidatedField
              label={translate('comexApp.bank.details.apiSettings.sellConfirmationEndpoint')}
              id="sell-confirmation-endpoint"
              name="sellConfirmationEndpoint"
              data-cy="sellConfirmationEndpoint"
              type="text"
              value={valueSellConfirmationEndpoint}
              onChange={handleValueSellConfirmationEndpoint}
            />

            <Grid container spacing={2}>
              <Grid item md={6} sm={6} xs={5}>
                <ValidatedField
                  label={translate('comexApp.bank.details.apiSettings.vendorName')}
                  id="vendor-name"
                  name="vendorName"
                  data-cy="vendorName"
                  type="text"
                  value={valueVendorName}
                  onChange={handleValueVendorName}
                />
              </Grid>
              <Grid item md={6} sm={6} xs={7}>
                <ValidatedField
                  label={translate('comexApp.bank.details.apiSettings.vendorCif')}
                  id="vendorCif"
                  name="vendorCif"
                  data-cy="vendorCif"
                  type="number"
                  value={valueVendorCif}
                  onChange={handleValueVendorCif}
                  inputProps={{
                    pattern: "[0-9]*",
                    inputMode: "numeric",
                  }}
                />
              </Grid>
            </Grid>
            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button
                color="primary"
                onClick={handleApiSettings}
                value={0}
                hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BANK)}>
                <FontAwesomeIcon icon="plus"/> <Translate contentKey="comexApp.bank.details.update"/>
              </Button>
            </Box>
          </Collapse>
        </div>

        <div className="branch-select-section">
          <Typography variant="h5" component="div" data-cy="branchSectionHeading" className="mb-4">
            {translate('comexApp.bank.details.branch.title')}
          </Typography>
          <Row className="mt-4">
            <Col sm={6} xs={12}>
              <Box sx={{display: 'flex', justifyContent: 'flex-start'}}>
                <div>
                  <FormControl sx={{width: {md: 300, xs: 280}, display: 'flex', justifyContent: 'flex-start'}}>
                    <Input type='select' width={"80%"}
                           defaultValue=''
                           onChange={handleChange}
                    >
                      <option value='' label={translate('comexApp.bank.details.branch.selectPlaceholder')}

                              disabled={true}></option>
                      {branchList.map(branch => {
                        if (branch?.bank?.name === bankEntity?.name) {

                          // getAreaManager(branch)
                          return (
                            <option
                              key={branch?.name}
                              value={`${branch?.name}|${branch?.id}|${branch?.areaManagerId}`}
                            >
                              {branch?.name}
                            </option>
                          );
                        }
                      })}
                    </Input>
                  </FormControl>
                </div>
              </Box>
            </Col>
            <Col sm={6} xs={12}>
              <Box sx={{display: 'flex', justifyContent: {sm: 'flex-end'}, mt: {xs: '10%', sm: 0}}}>
                <Button color="primary"
                        hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                        onClick={() => {
                          setOpenBranchCreate(true);
                        }}>
                  <FontAwesomeIcon icon="plus"/> <Translate contentKey="comexApp.bank.details.branch.addBtn"/>
                </Button>
              </Box>
            </Col>
          </Row>
        </div>

        {active ? (
          <div className="branch-section">
            <div style={{flexDirection: 'row', alignItems: 'center'}}>
              <div style={{display: 'inline-flex'}}>
                <h2 data-cy="bankDetailsHeading">{branchValue}&nbsp;(Branch)</h2>
              </div>
              <div style={{display: 'inline-flex', marginRight: '12px', marginLeft: '12px'}}>
                <Tooltip title={translate('comexApp.bank.details.editBranchBtn')}
                         hidden={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}>
                  <IconButton aria-label="edit" size="small" onClick={() => {
                    setOpenBranchEdit(true)
                  }}>
                    <EditIcon fontSize="small"/>
                  </IconButton>
                </Tooltip>
              </div>
              <div>
                <p>{translate("comexApp.bank.details.startDate")} : {formatDate(selectedBranchEntity?.createdDate)} </p>
              </div>
            </div>
            {/* <Row>*/}
            {/*  <Grid container>*/}
            {/*    <Grid item xs={12}>*/}
            {/*      <Box sx={{ display: 'inline-flex', width: '100%' }}>*/}
            {/*        <ListItem disablePadding>*/}
            {/*          /!* {accountsByBranchId && selectedBranchEntity && accountsByBranchId.map((user, i) => { *!/*/}
            {/*          /!*   if (areaManager?.authorities[0] === 'ROLE_AREA_MANAGER' && areaManager.id===selectedBranchEntity.areaManagerId) { *!/*/}
            {/*          /!*     return ( *!/*/}
            {/*          {areaManager && areaManagerId !== 'null' &&*/}
            {/*            <ListItemButton component="a" onClick={() => props.history.push(`/admin/user-management/${areaManager?.login}`)}*/}
            {/*              id={areaManager?.login} key={`user`} style={{ paddingLeft: '0px' }}>*/}
            {/*              <ListItemAvatar>*/}
            {/*                <Avatar className={classes.avatar}>*/}
            {/*                  {areaManager?.firstName?.charAt(0)}*/}
            {/*                  {areaManager?.lastName?.charAt(0)}*/}
            {/*                </Avatar>*/}
            {/*              </ListItemAvatar>*/}
            {/*              <ListItemText id="Name"*/}
            {/*                sx={{ textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right' }}>*/}
            {/*                <Typography variant='body1'>*/}
            {/*                  {areaManager?.firstName}&nbsp;{areaManager?.lastName}*/}
            {/*                  &nbsp;*/}
            {/*                  <span className='text-secondary'>(Area Manager)</span>*/}
            {/*                </Typography>*/}
            {/*              </ListItemText>*/}
            {/*            </ListItemButton>*/}
            {/*          }*/}
            {/*          /!*     )} *!/*/}
            {/*          /!* })} *!/*/}
            {/*        </ListItem>*/}
            {/*      </Box>*/}
            {/*    </Grid>*/}
            {/*  </Grid>*/}
            {/* </Row>*/}

            <Box className="area-manager-name" sx={{display: 'flex', alignItems: 'center'}}>
              <Box
                sx={{
                  width: 12,
                  height: 32,
                  mr: 2,
                  backgroundColor: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              />
              <Typography variant="h6" className="mt-2 mb-1" fontWeight="bold">
                <Translate contentKey="comexApp.bank.areaManager"/>
              </Typography>
            </Box>
            <Paper sx={{width: '100%', overflow: 'hidden', mt: 2, mb: 8}}>
              <List
                sx={{
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 300,
                  '& ul': {padding: 0},
                }}
              >
                {accountsUser && areaManagerId && accountsUser?.map((user, i) => {
                  const isAreaManager = user?.authorities.includes('ROLE_AREA_MANAGER');
                  if (isAreaManager && user?.id === +areaManagerId) {
                    return (
                      <li key={`user-${i}`}>
                        <ul>
                          <ListItemButton component="a"
                                          onClick={() => props.history.push(`/admin/user-management/${user?.login}`)}
                                          id={user?.login}
                                          style={{paddingLeft: '10px'}}>
                            <Grid container spacing={2}>
                              <Grid item md={8} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar
                                  sx={{minWidth: {xs: '3px', md: '56px'}, marginRight: {xs: '10px', md: '0px'}}}>
                                  <Avatar className={classes.avatar}>
                                    {user?.firstName?.charAt(0)}
                                    {user?.lastName?.charAt(0)}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText id="Name"
                                              sx={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right'}}>
                                  {user?.firstName}&nbsp;{user?.lastName}
                                </ListItemText>
                              </Grid>
                              <Grid item md={4} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar
                                  sx={{minWidth: {xs: '3px', md: '56px'}, marginRight: {xs: '10px', md: '0px'}}}>
                                  <EmailIcon color="disabled"/>
                                </ListItemAvatar>
                                <ListItemText id="Email"
                                              sx={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right'}}>
                                  {user?.email}
                                </ListItemText>
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        </ul>
                      </li>
                    )
                  }
                })}
              </List>
            </Paper>

            {branchList?.map((branch) => {
              if (branch?.name === branchValue) {
                return (
                  <Row className="branch-approval" key={branch?.name}>
                    <Col sm="6">
                      <div className="purchase-section"
                           style={{backgroundColor: `${branch.managerApproval ? '#0f3e58' : '#959595'}`}}>
                        <Grid container spacing={2}>
                          <Grid item md={1} xs={2}>
                            <Box className="mt-2">
                              <VerticalAlignBottomIcon sx={{transform: 'rotate(90deg)'}}/>
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body1" className="my-2" fontWeight="bold" color='#FFFFFF'>
                              <Translate contentKey="comexApp.bank.details.branch.managerApproval"/>
                            </Typography>
                            <Typography variant="caption" className="mb-3">
                              <Translate contentKey="comexApp.bank.details.branch.managerApprovalDetail"/>
                            </Typography>
                          </Grid>
                          <Grid item md={3} xs={2}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                              {branch.managerApproval ? (
                                <Tooltip title={translate('comexApp.bank.details.switch.required')}>
                                  <Switch
                                    aria-label="required"
                                    color="info"
                                    edge="end"
                                    checked={branch.managerApproval ? switchState.required : switchState.notRequired}
                                    onChange={handleSwitchManager(branch)}
                                    name="managerApproval"
                                    disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title={translate('comexApp.bank.details.switch.notRequired')}>
                                  <Switch
                                    aria-label="notRequired"
                                    color="info"
                                    edge="end"
                                    checked={branch.managerApproval ? switchState.required : switchState.notRequired}
                                    onChange={handleSwitchManager(branch)}
                                    name="managerApproval"
                                    disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </Grid>
                          <Grid item md={1} xs={2}>
                            <Box className="mt-2">
                              <AccessAlarmsIcon/>
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="caption" className="mb-3">
                              <Translate contentKey="comexApp.bank.details.branch.escalateApprovalDetail"
                                         interpolate={{approvalTimePeriod: approvalTime}}
                              />
                            </Typography>
                          </Grid>
                          <Grid item md={3} xs={2}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                              {branch.escalateApproval ? (
                                <Tooltip title={translate('comexApp.bank.details.switch.required')}>
                                  <Switch
                                    aria-label="required"
                                    color="info"
                                    edge="end"
                                    checked={branch.escalateApproval ? switchState.required : switchState.notRequired}
                                    onChange={handleSwitchEscalate(branch)}
                                    name="managerApproval"
                                    disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title={translate('comexApp.bank.details.switch.notRequired')}>
                                  <Switch
                                    aria-label="notRequired"
                                    color="info"
                                    edge="end"
                                    checked={branch.escalateApproval ? switchState.required : switchState.notRequired}
                                    onChange={handleSwitchEscalate(branch)}
                                    name="managerApproval"
                                    disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </div>

                    </Col>
                    <Col sm="6">
                      <div className="transfer-section mt-4 mt-sm-0"
                           style={{backgroundColor: `${branch.customerApproval ? '#5BA7BF' : '#959595'}`}}>
                        <Grid container spacing={2}>
                          <Grid item md={1} xs={2}>
                            <Box className="mt-2">
                              <SwapHorizIcon/>
                            </Box>
                          </Grid>
                          <Grid item xs={8}>
                            <Typography variant="body1" className="my-2" fontWeight="bold" color='#FFFFFF'>
                              <Translate contentKey="comexApp.bank.details.branch.customerApproval"/>
                            </Typography>
                            <Typography variant="caption" className="mb-4">
                              <Translate contentKey="comexApp.bank.details.branch.customerApprovalDetail"/>
                            </Typography>
                          </Grid>
                          <Grid item md={3} xs={2}>
                            <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                              {branch.customerApproval ? (
                                <Tooltip title={translate('comexApp.bank.details.switch.required')}>
                                  <Switch
                                    aria-label="required"
                                    color="primary"
                                    edge="end"
                                    checked={branch.customerApproval ? switchState.required : switchState.notRequired}
                                    onChange={handleSwitchCustomer(branch)}
                                    name="customerApproval"
                                    disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                                  />
                                </Tooltip>
                              ) : (
                                <Tooltip title={translate('comexApp.bank.details.switch.notRequired')}>
                                  <Switch
                                    aria-label="notRequired"
                                    color="primary"
                                    edge="end"
                                    checked={branch.customerApproval ? switchState.required : switchState.notRequired}
                                    onChange={handleSwitchCustomer(branch)}
                                    name="customerApproval"
                                    disabled={account?.authorities?.includes(AUTHORITIES.ALSHMOKH_USER) && !account?.authorities?.includes(AUTHORITIES.SUBADMIN_BRANCH)}
                                  />
                                </Tooltip>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                    </Col>
                  </Row>
                );
              }
            })
            }

            <Box className="branch-manager-name" sx={{display: 'flex', alignItems: 'center'}}>
              <Box
                sx={{
                  width: 12,
                  height: 32,
                  mr: 2,
                  backgroundColor: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              />
              <Typography variant="h6" className="mt-2 mb-1" fontWeight="bold">
                <Translate contentKey="comexApp.bank.details.branchManager"/>
              </Typography>
            </Box>
            <Paper sx={{width: '100%', overflow: 'hidden', mt: 2, mb: 8}}>
              <List
                sx={{
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 300,
                  '& ul': {padding: 0},
                }}
              >
                {accountsByBranchId && accountsByBranchId?.map((user, i) => {
                  // Check if the user's authorities include 'ROLE_BRANCH_MANAGER'
                  const isBranchManager = user?.authorities.includes('ROLE_BRANCH_MANAGER');
                  if (isBranchManager) {
                    return (
                      <li key={`user-${i}`}>
                        <ul>
                          <ListItemButton component="a"
                                          onClick={() => props.history.push(`/admin/user-management/${user.login}`)}
                                          id={user.login}
                                          style={{paddingLeft: '10px'}}>
                            <Grid container spacing={2}>
                              <Grid item md={8} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar
                                  sx={{minWidth: {xs: '3px', md: '56px'}, marginRight: {xs: '10px', md: '0px'}}}>
                                  <Avatar className={classes.avatar}>
                                    {user?.firstName?.charAt(0)}
                                    {user?.lastName?.charAt(0)}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText id="Name"
                                              sx={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right'}}>
                                  {user?.firstName}&nbsp;{user?.lastName}
                                </ListItemText>
                              </Grid>
                              <Grid item md={4} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar
                                  sx={{minWidth: {xs: '3px', md: '56px'}, marginRight: {xs: '10px', md: '0px'}}}>
                                  <EmailIcon color="disabled"/>
                                </ListItemAvatar>
                                <ListItemText id="Email"
                                              sx={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right'}}>
                                  {user?.email}
                                </ListItemText>
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        </ul>
                      </li>
                    )
                  }
                })}
              </List>
            </Paper>
            <Box className="staff-name" sx={{display: 'flex', alignItems: 'center'}}>
              <Box
                sx={{
                  width: 12,
                  height: 32,
                  mr: 2,
                  backgroundColor: 'primary.dark',
                  '&:hover': {
                    backgroundColor: 'primary.main',
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              />
              <Typography variant="h6" className="mt-2 mb-1" fontWeight="bold">
                <Translate contentKey="comexApp.bank.details.staff"/>
              </Typography>
            </Box>
            <Paper sx={{width: '100%', overflow: 'hidden', mt: 2, mb: 8}}>
              <List
                sx={{
                  position: 'relative',
                  overflow: 'auto',
                  maxHeight: 300,
                  '& ul': {padding: 0},
                }}
              >
                {accountsByBranchId?.map((user, i) => {
                  if (user?.authorities[0] === 'ROLE_BANK_STAFF') {
                    return (
                      <li key={`user-${i}`}>
                        <ul>
                          <ListItemButton component="a"
                                          onClick={() => props.history.push(`/admin/user-management/${user.login}`)}
                                          id={user.login}
                                          style={{paddingLeft: '10px'}}>
                            <Grid container spacing={2}>
                              <Grid item md={8} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar
                                  sx={{minWidth: {xs: '3px', md: '56px'}, marginRight: {xs: '10px', md: '0px'}}}>
                                  <Avatar className={classes.avatar}>
                                    {user?.firstName?.charAt(0)}
                                    {user?.lastName?.charAt(0)}
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText id="Name"
                                              sx={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right'}}>
                                  {user?.firstName}&nbsp;{user?.lastName}
                                </ListItemText>
                              </Grid>
                              <Grid item md={4} xs={6} sx={{display: 'flex', alignItems: 'center'}}>
                                <ListItemAvatar
                                  sx={{minWidth: {xs: '3px', md: '56px'}, marginRight: {xs: '10px', md: '0px'}}}>
                                  <EmailIcon color="disabled"/>
                                </ListItemAvatar>
                                <ListItemText id="Email"
                                              sx={{textAlign: Storage.session.get('locale') === 'en' ? 'left' : 'right'}}>
                                  {user?.email}
                                </ListItemText>
                              </Grid>
                            </Grid>
                          </ListItemButton>
                        </ul>
                      </li>
                    )
                  }
                })}
              </List>
            </Paper>
          </div>
        ) : (
          <Typography variant="body2" color="disabled">
            <Translate contentKey="comexApp.bank.details.notActive"/>
          </Typography>
        )}
        <Box sx={{display: 'flex', justifyContent: 'flex-start'}} className="back-button mt-4">
          <Button tag={Link} to="/bank" data-cy="entityDetailsBackButton"
                  onClick={() => {
                    window.localStorage.removeItem('selectedBranch');
                    localStorage.removeItem("feeAmount");
                  }}>
            <FontAwesomeIcon icon="arrow-left"/>{' '}
            <Translate contentKey="entity.action.back">Back</Translate>
          </Button>
        </Box>
      </ThemeProvider>
    </Container>
  );
};

export default BankDetail;
